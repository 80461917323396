.social-media-section {
    padding-top: 32px;
    p {
        opacity: 0.3;
        margin-bottom: 16px;
    }
}

.social-media-wrapper {
    display: flex;
    gap: 8px;
}
