@import "styles/mixins";

.form-container {
    padding: 32px;
    border-radius: 16px;
    background: var(--black);
    display: flex;
    flex-direction: column;
    gap: 32px 0;

    .form-body {
        display: flex;
        flex-wrap: wrap;
        gap: 32px 8px;
        align-content: flex-start;
    }
    .form-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        flex-wrap: wrap;
        gap: 24px 8px;
    }

    .form-control-container.form-item-half {
        flex: 1 1 calc(50% - 8px);
    }

    .form-control-container {
        flex: 1 1 100%;
    }
    .checkbox-container {
        flex: 1 1 50%;
        align-self: center;
    }

    @include w($lg) {
        gap: 24px 0;
        padding: 16px;
        .form-body {
            gap: 16px;
        }
        .checkbox-container {
            padding: 8px 0;
        }
        .form-control-container {
            flex: 1 1 100% !important;
        }
    }
    @include w($md) {
        .button-submit {
            width: 100%;
        }
    }
}

.thankyou-container {
    padding: 16px;
    border-radius: 16px;
    background: var(--black);
}
.thankyou-wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 8px;
}

.thankyou-background {
    background-color: var(--white-secondary-12);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 46.7% 100%, 29.5% 66.6%, 0 66.6%);
    backdrop-filter: blur(40px);
    border-radius: 4px;
    width: 100%;
    padding: 24px;
}

.thankyou-background h3 {
    margin-bottom: 16px;
}

.thankyou-background .body1 {
    opacity: 0.8;
}

.thankyou-background > .body1 {
    border-bottom: 1px solid var(--white-secondary-12);
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.thankyou-background-bottom {
    display: flex;
    width: 52%;
}

.thankyou-background-bottom .body1 {
    margin-right: 32px;
    width: 190px;
}

.thankyou-background .social-buttons {
    display: flex;
    gap: 8px;
}

.thankyou-bottom-wrapper {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
    .thankyou-wrapper {
        height: 566px;
    }

    .thankyou-background {
        padding: 12px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 39.8% 100%, 25.5% 82.8%, 0 82.8%);
    }

    .thankyou-background-bottom {
        flex-direction: column;
        width: 100%;
    }

    .thankyou-background-bottom .body1 {
        margin-right: 0;
        width: 100%;
        margin-bottom: 17px;
    }

    .social-buttons {
        margin-left: auto;
        margin-right: 0;
    }
}

.form-type-change {
    display: flex;
    flex: 1 1 100%;
    gap: 4px;
    border-radius: 2px;
    padding: 4px;
    margin-bottom: 8px;
    background: rgba(255, 255, 255, 0.2);
    @include w($md) {
        flex-direction: column;
    }
    &__item {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black);
        border-radius: 1px;
        text-transform: uppercase;
        background-color: transparent;
        opacity: 0.5;
        transition: 0.3s;
        font-weight: 600;
        color: #fff;
        user-select: none;
        &.active {
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.2);
            cursor: default;
        }
        &:hover:not(.active) {
            opacity: 1;
        }
        @include w($md) {
            padding: 16px 0;
            font-size: 14px;
        }
    }
}
