@import "styles/mixins";

.benefits-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.benefit-card {
    width: calc(50% - 4px);
    padding: 24px;
    border-radius: 8px;
    background-color: #fff;

    &__title {
        margin-bottom: 16px;
    }
    &__text {
        line-height: 1.3;
    }
    a {
        color: var(--green);
    }
    &__large {
        width: 100%;
    }
    @include w($xl) {
        padding: 16px;
    }
    @include w($md) {
        width: 100%;
    }
}
