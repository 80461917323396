@import "styles/typography";
@import "styles/mixins";

.checkbox-container {
    display: flex;
    align-items: center;
}
.checkbox {
    display: inline-block;
    align-self: flex-start;
}

.checkbox-label {
    padding-left: 12px;
    @include body4;
    color: rgba(#fff, 0.7);
    a {
        color: #fff;
        transition: 0.3s ease-out;
        &:hover {
            color: var(--toxicGreen);
        }
    }
}

.checkbox input {
    display: block;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: var(--white-secondary-12);
    border-radius: 1px;
    cursor: pointer;
}

.checkbox input:hover {
    background-color: var(--white-secondary-20);
}

.checkbox input.checked {
    position: relative;
    background-color: var(--toxicGreen);
}

input.checked::before {
    content: url("../../images/icons/checkmark.svg");
    position: absolute;
    left: 4px;
}
