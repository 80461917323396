@import "styles/mixins";
@import "styles/typography";

.our-history-section {
    position: relative;
    border-radius: 16px;
    background-color: var(--black);
    background-position: center;
    background-size: contain;
    overflow: hidden;
    margin-bottom: 8px;
    background-repeat: no-repeat;
    min-height: 800px;
    transform: translate3d(0, 0, 0);

    .our-history-section-gradient {
        display: block;
        position: absolute;
        width: 100%;
        height: 80%;
        bottom: 0;
        // background: var(--greenLinearGradient);
    }
    @include w($lg) {
        background-image: none !important;
    }
}

.our-history {
    padding: 32px;
    position: relative;
    z-index: 1;
    &__title {
        position: absolute;
    }
    @include w($lg) {
        padding: 16px;
        &__title {
            position: relative;
            padding-bottom: 24px;
        }
    }
}

.our-history-cards {
    display: grid;
    grid-template-columns: 5fr 3.5fr 3.5fr;
    @include w($xl) {
        display: block;
        .history-card-wrapper {
            width: 50%;
            &:nth-child(odd) {
                margin-left: auto;
            }
        }
    }
    @include w($lg) {
        .history-card-wrapper {
            width: 100%;
            & + .history-card-wrapper {
                margin-top: 24px;
            }
        }
    }
}

.history-card-wrapper {
    position: relative;

    .history-card {
        position: relative;
        z-index: 10;
        border-radius: 8px;
        padding: 24px;
        backdrop-filter: blur(30px);
        background-color: var(--white-secondary-12);
        transform: translate3d(0, 0, 0);

        .gradient-text {
            @include h3;
            padding-bottom: 11px;
            margin-bottom: 0;
        }
        .body1 {
            max-width: 292px;
        }
    }
    .angle-decor {
        backdrop-filter: blur(30px);
        background-color: rgba(#fff, 0.2);
    }

    &:nth-child(1) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        @include angle-position(7, top-right);
    }
    &:nth-child(2) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        @include angle-position(6, bottom-right);
    }
    &:nth-child(3) {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        @include angle-position(5, bottom-left);
    }
    &:nth-child(4) {
        grid-column: 2 / 3;
        grid-row: 4 / 5;
        @include angle-position(4, bottom-left);
    }
    &:nth-child(5) {
        grid-column: 1 / 2;
        grid-row: 5 / 6;
        @include angle-position(3, bottom-right);
    }
    &:nth-child(6) {
        grid-column: 2 / 3;
        grid-row: 6 / 7;
        @include angle-position(3, bottom-right);
    }
    &:nth-child(7) {
        grid-column: 3 / 4;
        grid-row: 7 / 8;
        @include angle-position(4, bottom-left);
    }
    &:nth-child(8) {
        grid-column: 2 / 3;
        grid-row: 8 / 9;
        @include angle-position(4, bottom-left);
    }
    &:last-child {
        .angle-decor {
            display: none;
        }
    }
    @include w($xl) {
        @include reset-absolute;
        &:nth-child(1) {
            @include angle-position(7, bottom-left);
        }
        &:nth-child(2) {
            @include angle-position(6, bottom-right);
        }
        &:nth-child(3) {
            @include angle-position(5, bottom-left);
        }
        &:nth-child(4) {
            @include angle-position(4, bottom-right);
        }
        &:nth-child(5) {
            @include angle-position(3, bottom-left);
        }
    }
    @include w($lg) {
        .history-card {
            padding: 16px 16px 20px;
        }
        .angle-decor {
            display: none;
        }
    }
}
