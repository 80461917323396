@import "./variables";

@mixin w($window-size) {
    @media screen and (max-width: ($window-size - 1px)) {
        @content;
    }
}
@mixin min-w($window-size) {
    @media screen and (min-width: ($window-size)) {
        @content;
    }
}

@mixin min-hover($window-size) {
    @include min-w($window-size) {
        &:hover {
            @content;
        }
    }
}

@mixin hover {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}

@mixin for-safary {
    @supports (hanging-punctuation: first) and (-webkit-appearance: none) {
        @content;
    }
    // @media not all and (min-resolution: 0.001dpcm) {
    //     @supports (-webkit-appearance: none) {
    //         @content;
    //     }
    // }
    // @media not all and (min-resolution: 0.001dpcm) {
    //     @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    //         @content;
    //     }
    // }
}
@mixin for-firefox {
    @supports (-moz-appearance: none) {
        @content;
    }
}

@mixin for-safari-and-firefox {
    @include for-safary {
        @content;
    }
    @include for-firefox {
        @content;
    }
}

@mixin product-item--hover {
    transform: scale(0.8);
}

@mixin label-style {
    border-radius: 64px;
    color: var(--emerald);
    border: 1px solid currentColor;
    padding: 1px 8px 0;
    height: 22px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

@mixin reset-absolute {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
}
@mixin angle-position($zindex, $position) {
    .angle-decor {
        z-index: $zindex;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        @if $position == bottom-left {
            bottom: 0;
            left: 0;
            transform: rotate(45deg) translate(50%, 50%);
        }
        @if $position == bottom-right {
            bottom: 0;
            right: 0;
            transform: rotate(-45deg) translate(-50%, 50%);
        }
        @if $position == top-right {
            top: 0;
            right: 0;
            transform: rotate(45deg) translate(-50%, -50%);
        }
    }
}
