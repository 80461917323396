@import "styles/_typography";

.text-with-icon {
    display: inline-flex;
    align-items: center;
    opacity: 0.7;
    margin-right: 16px;
    &__icon {
        display: flex;
        margin-right: 6px;
    }
    &__text {
        text-transform: uppercase;
    }
}
