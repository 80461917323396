@import "styles/mixins";

.head-office {
    display: flex;
    gap: 8px;
    padding-bottom: 40px;
    @include w($lg) {
        flex-direction: column-reverse;
        padding-bottom: 24px;
    }

    & > * {
        border-radius: 8px;
    }

    &__left {
        flex: 0 0 33.33%;
        background-color: var(--grey);
        padding: 32px;

        @include w($lg) {
            padding: 16px;
        }

        & > :first-child {
            margin-bottom: 32px;

            @include w($lg) {
                width: 56px;
                margin-bottom: 24px;
            }
        }
    }

    &__right {
        flex: 2;
        width: 50%;
        position: relative;
        overflow: hidden;
        &::after {
            content: url("data:image/svg+xml,%3Csvg viewBox='0 0 227 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M-7.62939e-05 0H226.566V72C226.566 63.1631 219.403 56 210.566 56H69.8739C65.6669 56 61.6299 54.3428 58.6349 51.3877L11.2389 4.6123C8.24492 1.65723 4.20692 0 -7.62939e-05 0Z' fill='white'/%3E%3C/svg%3E%0A");
            position: absolute;
            right: -1px;
            top: -1px;
            width: 227px;
            height: 72px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @include w($lg) {
            width: 100%;
            &::after {
                width: 100px;
                height: 25px;
            }
        }
    }

    em {
        font-style: normal;
        fill: transparent;
    }
}

.about-us-photo {
    padding-bottom: 40px;

    img {
        border-radius: 16px;
    }
    @include w($lg) {
        padding-bottom: 24px;
    }
}
