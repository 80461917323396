@import "styles/mixins";
@import "styles/typography";

.solution-card {
    position: absolute;
    width: 248px;
    padding: 24px;
    border: 1px solid var(--white-secondary-20);
    border-radius: 8px;
    background: var(--white-12, rgba(255, 255, 255, 0.12));
    backdrop-filter: blur(40px);
    transform: translate3d(0, 0, 0);
    &__title {
        @include h1;
        margin-bottom: 8px;
        background: var(--Green---White-gradient, linear-gradient(90deg, #fff 0%, #0f0 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    &:nth-child(1) {
        top: 60%;
        left: 32px;
    }
    &:nth-child(2) {
        top: 80%;
        right: 55%;
    }
    &:nth-child(3) {
        top: 90%;
        right: 25%;
    }
    &:nth-child(4) {
        top: 80%;
        right: 32px;
    }
    @include w($lg) {
        &:nth-child(1) {
            top: 50%;
            left: 16px;
        }
        &:nth-child(2) {
            top: 50%;
            right: 16px;
        }
        &:nth-child(3) {
            top: 80%;
            left: 16px;
        }
        &:nth-child(4) {
            top: 100%;
            right: 16px;
        }
    }
}

.solution-card img {
    width: 64px;
    margin-bottom: 16px;
}

.solution-card p {
    opacity: 0.7;
}

@media screen and (max-width: 1024px) {
    .solution-card {
        width: 167px;
        padding: 16px;
    }

    .solution-card img {
        width: 48px;
    }

    .solution-card p {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.01em;
    }
}
