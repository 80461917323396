@import "styles/typography";
@import "styles/mixins";

.material-dropdown {
    color: #fff;
    font-family: "TT Hoves W05 Medium", sans-serif;
    .MuiTouchRipple-root {
        display: none;
    }
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator {
        &:hover {
            background-color: rgba(0, 0, 0, 0) !important;
        }
        svg {
            opacity: 0;
        }
    }
    .MuiInputAdornment-root.MuiInputAdornment-positionStart {
        pointer-events: none;
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
        border-radius: 2px;
    }
    .MuiInputBase-root {
        height: 64px;
        padding-right: 16px !important;
        padding-left: 24px !important;
    }
    &__label.MuiFormLabel-root {
        font-family: "TT Hoves W05 Medium", sans-serif;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
        padding-bottom: 8px;
        opacity: 0.7;
    }
    .MuiInputBase-input {
        color: #fff;
        @include body1;
    }
    .MuiPaper-root.MuiPaper-elevation.MuiAutocomplete-paper {
        margin-top: 8px;
    }
    .MuiAutocomplete-listbox {
        color: #fff;
        background-color: var(--darkGrey);
        @include body1;
        .MuiAutocomplete-option {
            * {
                font-family: "TT Hoves W05 Medium", sans-serif;
            }
            height: 64px;
            padding: 7px 15px 7px 24px;
            &:hover {
                background-color: var(--white-secondary-20);
            }
        }
    }
    .material-dropdown__chevron {
        pointer-events: none;
        position: absolute;
        right: 15px;
        width: 16px;
        height: 16px;
        transition: all 0.3s ease-in-out;
        background: no-repeat center / contain
            url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIGQ9Im0zIDkgOSA3LjVMMjEgOSIvPjwvc3ZnPg==);
    }
    .MuiAutocomplete-root.Mui-expanded {
        .material-dropdown__chevron {
            transform: rotate(180deg);
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 6L8 11L2 6' stroke='%2300FF00' stroke-width='2'/%3E%3C/svg%3E%0A");
        }
    }
    @include w($md) {
        .MuiInputBase-root {
            height: 56px;
        }
        .MuiAutocomplete-listbox {
            .MuiAutocomplete-option {
                height: 56px;
            }
        }
    }
}
