@import "styles/typography";
@import "styles/mixins";

.table-box {
    color: #fff;
    background: linear-gradient(180deg, rgba(25, 99, 107, 0) 45%, rgba(0, 255, 0, 0.7) 100%), var(--black);
    &__table {
        table {
            // display: block;
            // width: 100% !important;
            border-collapse: separate !important;
            border-spacing: 0;
            border-color: inherit;
            // height: auto !important;
        }
        th,
        td {
            // display: inline-block;
        }
        tbody {
            // display: flex;
            // flex-direction: column;
            backdrop-filter: blur(20px);
            tr {
                // display: flex;
                // height: auto !important;

                &:first-child {
                    td {
                        border-top: 1px solid rgba(255, 255, 255, 0.2);
                        background: rgba(255, 255, 255, 0.12);
                    }
                    td:first-child {
                        border-top-left-radius: 8px;
                    }
                    td:last-child {
                        border-top-right-radius: 8px;
                    }
                }
                &:last-child {
                    td:first-child {
                        border-bottom-left-radius: 8px;
                    }
                    td:last-child {
                        border-bottom-right-radius: 8px;
                    }
                }
                td {
                    border-right: 1px solid rgba(255, 255, 255, 0.2);
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    height: 64px !important;
                    // flex: 1 1 auto;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 120%;
                    padding-left: 5px;
                    padding-right: 5px;
                    &:first-child {
                        border-left: 1px solid rgba(255, 255, 255, 0.2);
                        min-width: 190px !important;
                        text-align: left;
                        // justify-content: flex-start;
                        padding: 0 16px;
                        background: rgba(255, 255, 255, 0.12);
                    }
                }
            }
        }
    }
    &__description {
        padding-top: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        opacity: 0.8;
        max-width: calc(100% - 210px);
    }
    .table-box__piece {
        width: 204px;
        height: 56px;
        position: absolute;
        bottom: -1px;
        right: -1px;
        background-image: url("../../images/table-piece.svg");
        background-repeat: no-repeat;
        background-position: bottom right;
    }
    @include w($lg) {
        &__table {
            position: relative;
            overflow-x: auto;
            display: block;
            width: calc(100vw - 48px);
            table {
                tr td {
                    min-width: 80px;
                    height: 40px !important;
                }
            }
        }
    }
    @include w($md) {
        .table-box__piece {
            width: 30%;
        }
        &__description {
            max-width: 70%;
        }
        &__table {
            // display: flex;
            // table {
            //     display: flex;
            // }
            // tbody {
            //     width: 100%;
            //     flex-direction: row;
            //     tr {
            //         flex: 1 1 auto;
            //         flex-direction: column;
            //         &:first-child {
            //             td {
            //                 border-left: 1px solid rgba(255, 255, 255, 0.2);
            //                 border-right: 1px solid rgba(255, 255, 255, 0.2);
            //                 border-bottom: none;
            //             }
            //             td:first-child {
            //                 justify-content: center;
            //                 border-bottom: none;
            //                 border-right: 1px solid rgba(255, 255, 255, 0.2);
            //                 min-width: auto !important;
            //                 text-align: center;
            //             }
            //             td:last-child {
            //                 border-top-right-radius: 0;
            //                 border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            //                 border-bottom-left-radius: 8px;
            //             }
            //         }
            //         &:last-child {
            //             td:first-child {
            //                 min-width: auto !important;
            //                 text-align: center;
            //                 justify-content: center;
            //                 border-left: none;
            //                 border-bottom-left-radius: 0;
            //                 border-top-right-radius: 8px;
            //                 border-top: 1px solid rgba(255, 255, 255, 0.2);
            //             }
            //             td:last-child {
            //                 // border-bottom-right-radius: 8px;
            //                 border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            //             }
            //         }
            //         td {
            //             width: 100% !important;
            //             border-bottom: none;
            //             border-top: 1px solid rgba(255, 255, 255, 0.2);
            //             height: 40px !important;
            //         }
            //     }
            // }
        }
    }

    // table-box-without-table
    &.table-box-without-table {
        background-image: linear-gradient(180deg, rgba(9, 20, 21, 0) 20%, #091415 100%),
            url("../../images/like-table-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        .gradient-text {
            padding-bottom: 10px;
            @include w($xl) {
                padding-bottom: 30px;
                font-size: 48px;
                letter-spacing: 0;
            }
            @include w($lg) {
                font-size: 32px;
            }
            @include w($sm) {
                padding-bottom: 16px;
            }
        }
    }
}
