@import "styles/mixins";

.tabs {
}
.tabs-nav {
    display: flex;
    gap: 4px;
    border-radius: 2px;
    padding: 4px;
    background-color: #fff;
    margin-bottom: 32px;
    @include w($md) {
        flex-direction: column;
    }
}
.tab-pane {
}

.tab-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    color: var(--black);
    border-radius: 1px;
    text-transform: uppercase;
    background-color: #fff;
    opacity: 0.5;
    transition: 0.3s;
    font-weight: 600;
    &.active {
        opacity: 1;
        background-color: var(--lightGreen);
        cursor: default;
    }
    &:hover {
        background-color: var(--lightGreen);
    }
    @include w($md) {
        padding: 16px 0;
        font-size: 14px;
    }
}
