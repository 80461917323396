@import "styles/mixins";

.navigation-sidebar {
    background-color: var(--black);
    border-radius: 16px;
    position: sticky;
    top: 100px;

    &__item {
        position: relative;
        padding: 32px;
        opacity: 0.5;
        transition: all 0.3s;
        text-transform: uppercase;
        &:hover {
            background-color: var(--white-secondary-12);
            opacity: 1;
            cursor: pointer;
            transition: all 0.3s;
        }
        &:after {
            opacity: 0;
        }
    }

    &__active {
        background-color: var(--white-secondary-12);
        opacity: 1;

        &:after {
            position: absolute;
            display: block;
            content: "";
            width: 6px;
            height: 6px;
            background-color: var(--toxicGreen);
            border-radius: 3px;
            right: 32px;
            top: 37px;
            opacity: 1;
        }
    }

    @include w($xl) {
        display: none;
    }
}
