@import "styles/mixins";

.instagram-news {
    position: relative;
    display: flex;
    align-items: center;
    height: 784px;
    background-color: var(--black);
    background-image: url("../../../images/instagramBackground.png");
    background-size: 63.5%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 16px;
    margin-bottom: 40px;
    overflow: hidden;
    @include w($lg) {
        margin-bottom: 24px;
        background-position: center 38%;
    }
}

.instagram-news .first {
    position: absolute;
}

.instagram-news:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--greenLinearGradient);
    border-radius: 16px;
}

.instagram-image-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    text-align: center;
    margin: 0 auto;
    max-width: 680px;
    width: 100%;
    padding-bottom: 20px;
}
.instagram-image-wrapper {
    a {
        z-index: 1;
        transition: transform 0.3s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }
    & > img {
        cursor: pointer;
        width: 96px;
        height: 96px;
    }
}

.instagram-news {
    .first-instagram-card {
        position: absolute;
        top: 70%;
        left: 32px;
    }
    .second-instagram-card {
        position: absolute;
        top: 120%;
        left: 10%;
    }
    .third-instagram-card {
        position: absolute;
        top: 100%;
        right: 10%;
    }
    .fourth-instagram-card {
        position: absolute;
        top: 60%;
        right: 32px;
    }
    @include w($lg) {
        .first-instagram-card {
            top: 25%;
            left: -32px;
        }
        .second-instagram-card {
            top: 90%;
            left: -32px;
        }
        .third-instagram-card {
            top: 90%;
            right: -32px;
        }
        .fourth-instagram-card {
            top: 20%;
            right: -32px;
        }
    }
}

@include w($lg) {
    .instagram-news {
        background-size: 88%;
    }
    .instagram-image-wrapper {
        max-width: 342px;
        width: 100%;
    }
}
