@import "styles/typography";
@import "styles/mixins";

.country-selector-container {
    display: flex;
}

.country-selector-controls {
    display: flex;
    flex-direction: column;
    gap: 24px 0;
}

.country-selector-popup {
    z-index: 2;
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    background-color: var(--black);
    padding: 32px;
    border-radius: 8px;
    width: 464px;
    opacity: 0;
    transform: translateY(-10px);
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-name: headerDropdownShow;
    animation-play-state: running;
    .header-button {
        position: absolute;
        right: 8px;
        top: 8px;
    }
    &__title {
        @include h4;
        margin-bottom: 32px;
    }
    .select-input {
        max-width: none;
    }
    @include w($md) {
        padding: 16px;
        top: calc(100% + 8px);
        width: 100%;
    }
}
