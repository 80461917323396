@import "styles/mixins";

/* h1 style */
@mixin h1 {
    font-size: 96px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.04em;
    @include w($lg) {
        font-size: 40px;
        letter-spacing: -0.02em;
    }
}

.h1,
h1 {
    @include h1;
}

/* h2 style */
@mixin h2 {
    font-size: 64px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.03em;
    @include w($lg) {
        font-size: 32px;
        letter-spacing: -0.02em;
    }
}

.h2,
h2 {
    @include h2;
}

/* h3 style */
@mixin h3 {
    font-size: 48px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.02em;
    @include w($lg) {
        font-size: 24px;
        font-weight: 600;
        line-height: 120%;
    }
}

.h3,
h3 {
    @include h3;
}

/* h4 style */
@mixin h4 {
    font-size: 32px;
    font-weight: 500;
    line-height: 120%;
    @include w($lg) {
        font-size: 24px;
    }
}

.h4,
h4 {
    @include h4;
}

/* Body 1 */
@mixin body1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.01em;
    @include w($lg) {
        font-size: 16px;
    }
}

.body1 {
    @include body1;
}

/* Body 2 */
@mixin body2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.01em;
    @include w($lg) {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.01em;
    }
}

.body2 {
    @include body2;
}

/* Body 3 */
@mixin body3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    @include w($lg) {
        font-size: 13px;
        line-height: 110%;
    }
}

.body3 {
    @include body3;
}

/* Body 4 */
@mixin body4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.01em;
    @include w($lg) {
        font-size: 14px;
        letter-spacing: 0.01em;
    }
}

.body4 {
    @include body4;
}

/* description-medium */
@mixin description-medium {
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    @include w($lg) {
        font-size: 20px;
    }
}

.description-medium {
    @include description-medium;
}

/* description-demibold */
@mixin description-demibold {
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    @include w($lg) {
        font-size: 20px;
        font-weight: 600;
    }
}

.description-demibold {
    @include description-demibold;
}
