@import "styles/mixins";

.product-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    @include w($md) {
        grid-template-columns: repeat(2, 1fr);
    }
    &.six-items {
        grid-template-columns: repeat(6, 1fr);
        @include w($lg) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include w($md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.product-item {
    padding: 16px 8px;
    border-radius: 8px;
    background-color: #fff;
    color: var(--black);
    text-align: center;
    transition: all 0.3s;
    &__image {
        max-width: 152px;
        aspect-ratio: 1 / 1;
        margin: 0 auto;
        transition: transform 0.3s;
    }
    &__name {
        margin-top: 4px;
        min-height: 52px;
        display: grid;
        place-items: center;
    }
    &:hover {
        color: var(--green);
        .product-item__image {
            @include product-item--hover;
        }
    }
    @include w($md) {
        &__image {
            max-width: 110px;
        }
    }
}
