@import "styles/mixins";

.product-card-container {
    display: grid;
    gap: 40px 0;
    padding-bottom: 40px;
}

.product-card {
    display: flex;
    height: 296px;
    overflow: hidden;
    border-radius: 8px;

    &__image {
        position: relative;
        flex: 1 1 50%;
        overflow: hidden;
        border-radius: 8px 0 0 8px;
        &-bg {
            transition: scale 0.3s ease-out;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &-logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            backdrop-filter: blur(20px);

            img {
                max-width: none;
                width: 280px;
                height: 96px;
            }
        }
        &::before {
            content: attr(data-index);
            z-index: 2;
            position: absolute;
            @include label-style;
        }
        svg {
            position: absolute;
            z-index: 1;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        flex: 1 1 50%;
        padding: 32px;
        background-color: var(--grey);
        color: var(--black);
        border-radius: 0 8px 8px 0;
        transition: background-color 0.3s ease-out;
        h3 {
            margin-bottom: 16px;
        }
        p {
            opacity: 0.8;
        }
        .button {
            margin-top: auto;
        }
    }
    &--reverse {
        flex-direction: row-reverse;
        .product-card__content {
            border-radius: 8px 0 0 8px;
        }
        .product-card__image {
            border-radius: 0 8px 8px 0;
            &::before {
                right: 0;
            }
            svg {
                right: 0;
                transform: scaleX(-1);
            }
        }
    }
    &:hover {
        .product-card__content {
            background-color: var(--greyHover);
        }
        .product-card__image-bg {
            scale: 1.02;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
        &__image {
            min-height: 200px;
            border-radius: 8px 8px 0 0 !important;
            &-logo {
                img {
                    width: 210px;
                    height: 72px;
                }
            }
            svg {
                width: 90px;
            }
        }
        &__content {
            padding: 16px;
            border-radius: 0 0 8px 8px !important;
            .button {
                margin-top: 24px;
            }
        }
    }
}
