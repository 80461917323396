@import "styles/typography";
@import "styles/mixins";

.job-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding-bottom: 40px;

    @include w($lg) {
        grid-template-columns: 1fr;
    }
}

.job-card {
    color: var(--black);
    background-color: var(--grey);
    padding: 32px;
    border-radius: 16px;
    transition: 0.3s;
    &__title {
        @include h4;
        margin-bottom: 16px;
        display: flex;
        gap: 0 8px;
        flex-wrap: wrap;
        transition: inherit;
        svg {
            transition: opacity 0.3s;
            opacity: 0;
        }
    }
    &__description {
        margin-bottom: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .job-labels {
        margin-bottom: 24px;
    }
    @include w($lg) {
        padding: 16px;
    }
    &:hover {
        background-color: var(--greyHover);

        .job-card__title {
            color: var(--green);
            svg {
                opacity: 1;
            }
        }
    }
}
