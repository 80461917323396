.label-button {
    border: 1px solid var(--emerald);
    border-radius: 64px;
    padding: 4px 8px;
    display: inline-block;
    white-space: nowrap;
    text-transform: uppercase;
}

.label-button span {
    color: var(--emerald);
    display: flex;
    align-items: center;
    gap: 4px;
}

.label-button div {
    width: 4px;
    height: 4px;
    background-color: var(--emerald);
    border-radius: 4px;
}
