@import "styles/mixins";

.team {
    $gap: 8px;
    column-gap: $gap;
    row-gap: 64px;
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0 64px;

    .team-member-card,
    .team-info-card {
        flex: 0 1 calc(25% - $gap);
    }
    .team-career-card {
        flex: 0 1 calc(50% - $gap);
    }
    @include w($xl) {
        row-gap: 32px;
        .team-member-card,
        .team-info-card {
            flex: 0 1 calc(50% - $gap);
        }
        .team-career-card {
            flex: 0 1 100%;
        }
    }
}

.team-member-card {
    color: var(--black);
    &__img {
        padding-bottom: 16px;
    }
    img {
        border-radius: 8px;
        aspect-ratio: 334 / 360;
        object-fit: cover;
    }
    .body1 {
        padding-top: 8px;
    }
}

.team-info-card {
    height: auto;
    &__icon {
        width: 72px;
    }
    &-inner {
        position: relative;
    }
    &__img {
        width: 100%;
        aspect-ratio: 334 / 360;
    }
    &__content {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px;
        .gradient-text {
            padding: 0 0 8px 0;
            margin: 0;
        }
        @include w(1366px) {
            .team-info-card__icon {
                width: 48px;
            }
        }
        @include w($lg) {
            padding: 16px;
        }
        @include w($sm) {
            div {
                margin-top: auto;
            }
            .team-info-card__icon {
                display: none;
            }
        }
    }
}

.team-career-card {
    &-inner {
        position: relative;
    }
    &__img {
        width: 100%;
    }
    &__content {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px;
        .h2 {
            padding-bottom: 16px;
        }
        .body1 {
            max-width: 440px;
        }
    }
    @include w($lg) {
        &__content {
            padding: 16px 16px 12%;
        }
    }
    @include w($sm) {
        &__content {
            padding: 16px 16px 23%;
            .body1 {
                padding-bottom: 16px;
            }
        }
        &__img {
            width: auto;
            min-height: 300px;
            object-fit: cover;
            border-radius: 8px;
            object-position: right;
        }
    }
}
