@import "styles/mixins";
@import "styles/typography";

.our-products {
    .label-button {
        margin: 0;
        @include w($lg) {
            margin-bottom: 16px;
        }
    }
}
.homepage-product-items {
    margin-top: 40px;
    margin-bottom: 40px;
    @include min-hover($lg) {
        .product-link__name {
            opacity: 0.5;
        }
    }
    @include w($lg) {
        margin-top: 16px;
    }
}
.product-link {
    height: 120px;
    display: flex;
    align-items: center;
    color: var(--black);
    position: relative;
    border-bottom: 1px solid var(--emerald-secondary-10);
    &:first-child {
        border-top: 1px solid var(--emerald-secondary-10);
    }
    &__name {
        transition: opacity 0.3s ease-in;
        position: relative;
        @include h2;
        .gradient-number {
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(100%);
        }
    }
    &__image {
        z-index: 1;
        position: absolute;
        top: -50%;
        right: 80px;
        aspect-ratio: 360 / 224;
        border-radius: 8px;
        width: 360px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        scale: 0;
        clip-path: inset(50% round 200px);
        transition: all 0.3s ease;
        &-logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 4px;
            backdrop-filter: blur(20px);
            img {
                max-width: none;
                width: 280px;
            }
        }
    }
    .button {
        margin-left: auto;
        opacity: 0;
    }
    @include min-hover($lg) {
        .product-link__name {
            opacity: 1;
        }
        .product-link__image {
            opacity: 1;
            scale: 1;
            clip-path: inset(0 0 0 round 0);
        }
        .button {
            opacity: 1;
        }
    }
    @include w($lg) {
        padding: 24px 0;
        display: block;
        height: auto;
        &__name {
            display: inline-block;
            @include h1;
            padding-bottom: 24px;
        }
        &__image {
            width: 100%;
            position: relative;
            inset: 0;
            opacity: 1;
            scale: none;
            clip-path: inset(0 0 0 round 0);

            &-logo {
                img {
                    width: 210px;
                }
            }
        }
        .button {
            display: none;
        }
    }
}
