@import "styles/mixins";

.news-cards {
    gap: 80px 8px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;

    @include w($md) {
        padding: 0 16px 40px;
        gap: 40px 8px;
    }
}

.news-card {
    position: relative;
    flex: 0 1 calc(33.3333% - 8px);

    &__image {
        aspect-ratio: 448 / 280;
        overflow: hidden;
        border-radius: 8px;
        img {
            transition: 0.5s;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__content {
        margin-top: 16px;
        color: var(--black);
        padding-right: 40px;
    }
    &__title {
        transition: color 0.3s;
    }
    &-date {
        margin-top: 16px;
    }
    &-labels {
        margin-bottom: 16px;
    }

    &--big {
        @include min-w($lg) {
            flex: 0 1 calc(66.66% - 8px);

            .news-card__image {
                aspect-ratio: 900 / 520;
                border-radius: 16px;
            }
            .news-card__content {
                width: 50%;
                color: #fff;
                position: absolute;
                padding: 16px;
                bottom: 8px;
                left: 8px;
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(40px);
            }
        }
    }
    &:hover {
        .news-card__title {
            color: var(--green);
        }
        .news-card__image img {
            transform: scale(1.02);
        }
    }

    @include w($lg) {
        &,
        &--big {
            flex: 0 1 calc(50% - 8px);
        }
    }
    @include w($md) {
        &,
        &--big {
            flex: 0 1 100%;
        }
    }
}

.subscribe-card {
    position: relative;
    border-radius: 16px;
    flex: 0 1 calc(66.66% - 8px);
    padding: 40px;
    background: linear-gradient(180deg, transparent 40%, rgba(0, 255, 0, 0.7) 100%), var(--black);
    &__label {
        &-label {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            @include label-style;
        }

        svg {
            position: absolute;
            top: -1px;
            right: -1px;
        }
    }
    &__content {
        max-width: 600px;
        h2 {
            margin-bottom: 24px;
        }
    }

    .subscribe-form {
        margin-top: 40px;
        &__input {
            position: relative;
            margin-bottom: 24px;
            .button {
                z-index: 1;
                position: absolute;
                top: 4px;
                right: 4px;
            }
        }
    }
    @include w($lg) {
        padding: 56px 16px 24px;
        flex: 0 1 calc(50% - 8px);
        .form-control__input {
            padding-left: 18px;
            padding-right: 56px;
        }
        .button {
            width: 48px;
            height: 48px;
        }
        &__label {
            svg {
                width: 200px;
            }
        }
    }
    @include w($md) {
        flex: 0 1 100%;
    }
}
