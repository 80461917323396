@import "styles/typography";
@import "styles/mixins";

.form-control-container {
}

.form-control {
    color: #fff;

    &__label {
        @include body3;
        text-transform: uppercase;
        padding-bottom: 8px;
        opacity: 0.7;
        display: block;
    }
    &__textarea,
    &__input {
        width: 100%;
        border-radius: 2px;
        padding-left: 24px;
        padding-right: 24px;
        background-color: var(--white-secondary-12);
        transition: all 0.3s ease-out;
        @include body1;
        &::placeholder {
            color: rgba(255, 255, 255, 0.3);
        }
        &:hover,
        &:focus {
            background-color: var(--white-secondary-20);
        }
    }
    &__textarea {
        padding-top: 20px;
        display: block;
        min-height: 136px;
        resize: none;
        outline: none;
        min-width: 100%;
    }
    &__input {
        height: 64px;
        @include w($lg) {
            height: 56px;
        }
    }
    &.invalid {
        .form-control__input {
            color: var(--red);
        }
    }
    .invalid-text {
        padding-top: 8px;
        color: var(--red);
        text-transform: uppercase;
        border-top: 2px solid var(--red);
        @include body3;
    }
}

.attach-file-wrapper {
    position: relative;
}

.attach-file {
    color: var(--toxicGreen);
    background-color: var(--white-secondary-12);
    border: 1px dashed var(--toxicGreen);
    max-width: 100%;
    transition: all 0.3s ease-in-out;
}

.attach-file.file-attached {
    display: flex;
    justify-content: flex-start;
    color: var(--white);
    border: none;
}
.attach-file.file-attached:before {
    content: "";
    display: block;
    width: 34px;
    height: 40px;
    margin: 0 18px 0 24px;
    background: no-repeat center / contain
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iNDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBkPSJNNC4zMTcgMEMxLjkzNyAwIDAgMS44NTUgMCA0LjEzMnYzMS43MzZDMCAzOC4xNDUgMS45MzcgNDAgNC4zMTcgNDBoMjUuMzZjMi4zOCAwIDQuMzE4LTEuODU1IDQuMzE4LTQuMTMzVjkuNzczcy4wNjctLjc2NC0uMjgtMS41MDdjLS4zMjQtLjY5Ny0uODU0LTEuMTgyLS44NTQtMS4xODJhMi4yNzkgMi4yNzkgMCAwIDAtLjAwNC0uMDA1bC02LjI5Mi01LjkwNmEuNjE2LjYxNiAwIDAgMC0uMDEtLjAxcy0uNTM3LS40OS0xLjMzNC0uODE2QzI0LjI4NC0uMDQgMjMuMzE3IDAgMjMuMzE3IDBMMjMuMzMyIDBINC4zMTdaIiBmaWxsPSIjZmZmIi8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgzNHY0MEgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==);
}

label.attach-file input[type="file"] {
    display: none;
}

.attach-file:hover {
    background-color: var(--white-secondary-20);
}

.attach-file:before {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-right: 8px;
    background: no-repeat center / contain
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjYyNSAxNy41SDQuMzc1YS42MjUuNjI1IDAgMCAxLS42MjUtLjYyNVYzLjEyNWEuNjI1LjYyNSAwIDAgMSAuNjI1LS42MjVoNy41bDQuMzc1IDQuMzc1djEwYS42MjQuNjI0IDAgMCAxLS42MjUuNjI1WiIgc3Ryb2tlPSIjMEYwIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTExLjg3NSAyLjV2NC4zNzVoNC4zNzUiIHN0cm9rZT0iIzBGMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
}
