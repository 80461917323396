@import "styles/mixins";

.news-slider-section {
    position: relative;
    color: var(--black);
    margin: 80px 32px;
    h2 {
        margin-bottom: 40px;
    }
    @include w($lg) {
        margin: 0 16px 64px;
    }
}
.news-slider {
    color: black;
    &__navigation {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        gap: 0 16px;
        @include w($md) {
            display: none;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        &:disabled {
            opacity: 0.3;
            pointer-events: none;
            cursor: default;
        }
    }
    .swiper-pagination {
        padding-top: 20px;
        @include min-w($lg) {
            display: none;
        }
    }
    .swiper-pagination-bullet {
        all: unset;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--black);
        opacity: 0.3;
        margin-right: 8px;
        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}
