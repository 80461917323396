@import "styles/typography";
@import "styles/mixins";

.search-container {
    // border: 1px solid red;
}
.search-dropdown {
    @include w($lg) {
        padding: 16px;
    }
}
.search-component {
}

.search-control {
    display: flex;
    align-items: center;
    height: 64px;
    border-radius: 2px;
    padding: 8px 8px 8px 20px;
    gap: 0 20px;
    background-color: rgba(255, 255, 255, 0.12);
    &__input {
        flex: 1 1 auto;
    }
    &__clear {
        height: 48px;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 0 40px;
        background-color: rgba(255, 255, 255, 0.12);
        text-transform: uppercase;
    }
    @include w($lg) {
        padding: 8px 10px;
        gap: 0 16px;
        height: 54px;
        &__clear {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            padding: 0;
            span {
                display: none;
            }
        }
    }
}

.search-results {
    &-title {
        padding: 24px 0;
        @include h4;
    }
    &-content {
        max-height: 50vh;
        overflow-y: auto;
    }
}
.result-category-block {
    padding-bottom: 16px;
    & + & {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        margin-top: 16px;
    }
    &__title {
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
        opacity: 0.7;
    }
    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 16px 0;
    }
    &__button {
        color: white;
    }
}

.result-item-card {
    display: flex;
    align-items: center;
    border-radius: 2px;
    position: relative;
    transition: 0.3s;
    height: 64px;
    img {
        width: 64px;
        height: 64px;
        aspect-ratio: 1/1;
        object-fit: contain;
    }
    &__desc {
        padding-left: 16px;
        p {
            &:first-child {
                @include body1;
            }
            &:last-child {
                padding-top: 10px;
                @include body3;
                text-transform: uppercase;
                opacity: 0.5;
            }
        }
    }
    .learn-more-arrow {
        opacity: 0;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        transition: inherit;
    }
    @include w($lg) {
        img {
            width: 48px;
            height: 48px;
        }
    }
    @include hover {
        .learn-more-arrow {
            opacity: 1;
        }
        background-color: rgba(255, 255, 255, 0.12);
    }
}
