@font-face {
    font-family: 'TT Hoves W05 Medium';
    font-weight: 500;
    src: url('../fonts/8672873/5b6b825d-5318-456e-8d13-155eafb06eb9.woff2') format('woff2'),
        url('../fonts/8672873/6c87afe7-a93c-44cf-95dd-91729617d8a9.woff') format('woff');
}
@font-face {
    font-family: 'TT Hoves W05 DemiBold';
    font-weight: 600;
    src: url('../fonts/8672879/ab11b063-1886-439a-b519-c6f23af213f6.woff2') format('woff2'),
        url('../fonts/8672879/e492ed0a-1872-4e37-997b-34393a108038.woff') format('woff');
}
:root {
    /* primary colors */
    --white: #ffffff;
    --grey: #e9eced;
    --toxicGreen: #00ff00;
    --emerald: #035c67;
    --darkGrey: #2f3133;
    --black: #121517;

    /* secondary colors */
    --greyHover: #f4f5f6;
    --green: #00d300;
    --lightGreen: #bef4be;
    --lightGreenHover: #a7f2a7;
    --greenWhiteGradient: linear-gradient(90deg, #ffffff 0%, #00ff00 100%);
    --greenEmeraldGradient: linear-gradient(90deg, #19636b 0%, #00ff00 100%);
    --greenLinearGradient: linear-gradient(180deg, rgba(25, 99, 107, 0) 40%, rgba(0, 255, 0, 0.7) 100%);
    --red: #ff4e4e;
    --yellow: #ffdb1b;
    --white-secondary-12: rgba(255, 255, 255, 0.12);
    --white-secondary-20: rgba(255, 255, 255, 0.2);
    --white-secondary-50: #ffffff80;
    --emerald-secondary-10: #035c671a;
    --emerald-secondary-20: #035c6733;
    --black-secondary-10: #1215161a;
    --black-secondary-30: #1215164d;
}
html {
    -webkit-text-size-adjust: 100%;
}
body {
    font-family: 'TT Hoves W05 Medium', sans-serif;
    color: var(--white);
    padding: 8px 0 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.button-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
}

a,
a:after {
    color: var(--white);
}

.uppercase {
    text-transform: uppercase;
}

.black-text {
    color: var(--black);
}

.white-text {
    color: var(--white);
}

.black-background {
    background-color: var(--black);
}

@media screen and (max-width: 1024px) {
    .button {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0em;
    }

    .display-none-mobile {
        display: none;
    }
}
