@import "styles/mixins";
@import "styles/typography";

.gradient-text {
    font-weight: 500;
    line-height: 100%;
    padding-bottom: 0.1em;
    &.gradient-text--black {
        color: var(--black);
        background: var(--greenEmeraldGradient);
        -webkit-background-clip: text;
    }
    &.gradient-text--white {
        color: var(--white);
        background: var(--greenWhiteGradient);
        -webkit-background-clip: text;
    }

    em,
    b,
    strong {
        font-style: normal;
        font-weight: normal;
        -webkit-text-fill-color: transparent;
    }
    img {
        width: 1.9em;
        height: 0.85em;
        border-radius: 4px;
        margin-top: 0.1em;
    }

    &--big {
        @include h1;
        margin-bottom: 40px;
    }
    &--middle {
        @include h2;
    }
    &--small {
        @include h4;
    }
    &--super-small {
        @include description-medium;
    }

    @include w($lg) {
        br {
            display: none;
        }
    }
}
