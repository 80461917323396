@import "styles/mixins";

.tags-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-top: 32px;
}

.tag-item {
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 64px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(3, 92, 103, 0.3);
    color: var(--emerald);
    text-transform: uppercase;
    transition: all 0.3s ease-out;
    &.selected {
        color: #fff;
        background-color: var(--emerald);
        border-color: rgba(18, 21, 22, 0.1);
    }
    &:hover:not(.selected) {
        border-color: transparent;
        background-color: rgba(3, 92, 103, 0.1);
    }
    @include w($md) {
        font-size: 14px;
        height: 32px;
        padding: 0 16px;
    }
}
