@import "styles/mixins";

.homepage-news-text {
    padding-right: 50px;
    .button {
        margin-top: 32px;
        margin-bottom: 40px;
    }
    @include w($lg) {
        padding-right: 0;
    }
}
.latest-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 8px;
}

.homepage-news-slider {
    position: relative;
    min-width: 100%;
}
