@import "styles/mixins";

.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
}

.button {
    background-color: var(--toxicGreen);
    color: var(--black);
    max-width: 280px;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s;
}

.button span {
    transition: color 0.3s;
}

.button:hover {
    background-color: var(--green);
}

.button-arrow:after {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-left: 8px;
    background: no-repeat center / contain
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjMTIxNTE3IiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0yLjUgOGgxMm0wIDAtNiA2bTYtNi02LTYiLz48L3N2Zz4=);
}

.button-eye:before {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-right: 8px;
    background: no-repeat center / contain
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIiBzdHJva2U9IiMxMjE1MTciIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiPjxwYXRoIGQ9Ik0xMC41IDQuMzc1QzQuMjUgNC4zNzUgMS43NSAxMCAxLjc1IDEwczIuNSA1LjYyNSA4Ljc1IDUuNjI1UzE5LjI1IDEwIDE5LjI1IDEwcy0yLjUtNS42MjUtOC43NS01LjYyNVoiLz48cGF0aCBkPSJNMTAuNSAxMi41YTIuNSAyLjUgMCAxIDAgMC01IDIuNSAyLjUgMCAwIDAgMCA1WiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC41KSIgZD0iTTAgMGgyMHYyMEgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==);
}

.download-button {
    background-color: var(--lightGreen);
}

.download-button:before {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-right: 8px;
    background: no-repeat center / contain
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjMTIxNTE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTEwIDExLjI1VjMuMTI1TTE2Ljg3NSAxMS44NzV2NC4zNzVhLjYyNC42MjQgMCAwIDEtLjYyNS42MjVIMy43NWEuNjI1LjYyNSAwIDAgMS0uNjI1LS42MjV2LTQuMzc1Ii8+PHBhdGggc3Ryb2tlPSIjMTIxNTE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTEzLjEyNSA4Ljc1IDEwIDExLjg3NSA2Ljg3NSA4Ljc1Ii8+PC9zdmc+);
}

.download-button:hover {
    background-color: var(--lightGreenHover);
}

.find-desktop {
    background-color: var(--toxicGreen);
    border-radius: 8px 2px;
    max-width: 366px;
}

.find-desktop:hover {
    background-color: var(--green);
}

.find-desktop:before,
.find-mobile:before {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-right: 8px;
    background: no-repeat center / contain url("../../../images/icons/blackMapPin.svg");
}

.find-mobile {
    background-color: var(--toxicGreen);
}

.find-mobile:hover {
    background-color: var(--green);
}

.banner {
    border-radius: 8px 2px;
    max-width: 366px;
}

.learn-more {
    background-color: var(--lightGreen);
    color: var(--emerald);
    max-width: 366px;
    border-radius: 8px 2px;
}

.learn-more:hover {
    background-color: var(--lightGreenHover);
}

.learn-more:after {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-left: 8px;
    background: no-repeat center / contain
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTggMnYxMm0wIDBMMiA4bTYgNiA2LTYiIHN0cm9rZT0iIzAzNUM2NyIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
}

.show-more {
    border: 1px solid var(--black-secondary-10);
    max-width: 400px;
    background-color: transparent;
}

.show-more:hover {
    border: none;
    background-color: var(--black-secondary-10);
}

.show-more:before {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-right: 8px;
    background: no-repeat center / contain
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjMTIxNTE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTUuNSA5Ljc2OWgtM3YzIi8+PHBhdGggZmlsbD0iIzEyMTUxNyIgZD0ibTEyLjkzIDEyLjQxNy41My0uNTMtMS4wNi0xLjA2LS41My41MyAxLjA2IDEuMDZabS04LjMwNS0uNTMuNTMtLjUzLS41My41M1ptNy4yNDQtLjUzYy0uNDQuNDQyLS45NjQuNzkyLTEuNTQgMS4wM2wuNTc1IDEuMzg2YTYuMjQ0IDYuMjQ0IDAgMCAwIDIuMDI3LTEuMzU2bC0xLjA2Mi0xLjA2Wm0tMS41NCAxLjAzYTQuNzQ1IDQuNzQ1IDAgMCAxLTEuODE3LjM2MnYxLjVjLjgyMSAwIDEuNjM0LS4xNjIgMi4zOTItLjQ3NmwtLjU3NS0xLjM4NlptLTEuODE3LjM2MmE0Ljc0NCA0Ljc0NCAwIDAgMS0xLjgxNi0uMzYybC0uNTc1IDEuMzg2Yy43NTguMzE0IDEuNTcuNDc2IDIuMzkxLjQ3NnYtMS41Wm0tMS44MTYtLjM2MmE0Ljc0NSA0Ljc0NSAwIDAgMS0xLjU0LTEuMDNsLTEuMDYyIDEuMDZhNi4yNDQgNi4yNDQgMCAwIDAgMi4wMjcgMS4zNTZsLjU3NS0xLjM4NlptLTEuNTQxLTEuMDNMMy4wMyA5LjIzNiAxLjk3IDEwLjNsMi4xMjUgMi4xMTggMS4wNi0xLjA2MloiLz48cGF0aCBzdHJva2U9IiMxMjE1MTciIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS13aWR0aD0iMS41IiBkPSJNMTEuNSA2LjIzaDN2LTMiLz48cGF0aCBmaWxsPSIjMTIxNTE3IiBkPSJtNC4wNyAzLjU4Mi0uNTMuNTMgMS4wNiAxLjA2LjUzLS41My0xLjA2LTEuMDZabTguMzA1LjUzLS41My41My41My0uNTNabS03LjI0NC41M2MuNDQtLjQ0Mi45NjQtLjc5MiAxLjU0LTEuMDNsLS41NzUtMS4zODZhNi4yNDQgNi4yNDQgMCAwIDAtMi4wMjcgMS4zNTZsMS4wNjIgMS4wNlptMS41NC0xLjAzYTQuNzQ0IDQuNzQ0IDAgMCAxIDEuODE3LS4zNjJ2LTEuNWMtLjgyMSAwLTEuNjM0LjE2Mi0yLjM5Mi40NzZsLjU3NSAxLjM4NlptMS44MTctLjM2MmMuNjIzIDAgMS4yNC4xMjMgMS44MTcuMzYybC41NzQtMS4zODZhNi4yNDQgNi4yNDQgMCAwIDAtMi4zOTEtLjQ3NnYxLjVabTEuODE3LjM2MmMuNTc1LjIzOCAxLjA5OS41ODggMS41NCAxLjAzbDEuMDYtMS4wNmE2LjI0NCA2LjI0NCAwIDAgMC0yLjAyNi0xLjM1NmwtLjU3NCAxLjM4NlptMS41NCAxLjAzIDIuMTI1IDIuMTIgMS4wNi0xLjA2My0yLjEyNS0yLjExOC0xLjA2IDEuMDYyWiIvPjwvc3ZnPg==);
}

.show-more-white {
    color: var(--white);
    max-width: 400px;
    background-color: transparent;
    border: 2px solid var(--white-secondary-12);
}

.show-more-white:before {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-right: 8px;
    background: no-repeat center / contain
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTUuNSA5Ljc2OWgtM3YzIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0ibTEyLjkzIDEyLjQxNy41My0uNTMtMS4wNi0xLjA2LS41My41MyAxLjA2IDEuMDZabS04LjMwNS0uNTMuNTMtLjUzLS41My41M1ptNy4yNDQtLjUzYy0uNDQuNDQyLS45NjQuNzkyLTEuNTQgMS4wM2wuNTc1IDEuMzg2YTYuMjQ0IDYuMjQ0IDAgMCAwIDIuMDI3LTEuMzU2bC0xLjA2Mi0xLjA2Wm0tMS41NCAxLjAzYTQuNzQ1IDQuNzQ1IDAgMCAxLTEuODE3LjM2MnYxLjVjLjgyMSAwIDEuNjM0LS4xNjIgMi4zOTItLjQ3NmwtLjU3NS0xLjM4NlptLTEuODE3LjM2MmE0Ljc0NCA0Ljc0NCAwIDAgMS0xLjgxNi0uMzYybC0uNTc1IDEuMzg2Yy43NTguMzE0IDEuNTcuNDc2IDIuMzkxLjQ3NnYtMS41Wm0tMS44MTYtLjM2MmE0Ljc0NSA0Ljc0NSAwIDAgMS0xLjU0LTEuMDNsLTEuMDYyIDEuMDZhNi4yNDQgNi4yNDQgMCAwIDAgMi4wMjcgMS4zNTZsLjU3NS0xLjM4NlptLTEuNTQxLTEuMDNMMy4wMyA5LjIzNiAxLjk3IDEwLjNsMi4xMjUgMi4xMTggMS4wNi0xLjA2MloiLz48cGF0aCBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS13aWR0aD0iMS41IiBkPSJNMTEuNSA2LjIzaDN2LTMiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJtNC4wNyAzLjU4Mi0uNTMuNTMgMS4wNiAxLjA2LjUzLS41My0xLjA2LTEuMDZabTguMzA1LjUzLS41My41My41My0uNTNabS03LjI0NC41M2MuNDQtLjQ0Mi45NjQtLjc5MiAxLjU0LTEuMDNsLS41NzUtMS4zODZhNi4yNDQgNi4yNDQgMCAwIDAtMi4wMjcgMS4zNTZsMS4wNjIgMS4wNlptMS41NC0xLjAzYTQuNzQ0IDQuNzQ0IDAgMCAxIDEuODE3LS4zNjJ2LTEuNWMtLjgyMSAwLTEuNjM0LjE2Mi0yLjM5Mi40NzZsLjU3NSAxLjM4NlptMS44MTctLjM2MmMuNjIzIDAgMS4yNC4xMjMgMS44MTcuMzYybC41NzQtMS4zODZhNi4yNDQgNi4yNDQgMCAwIDAtMi4zOTEtLjQ3NnYxLjVabTEuODE3LjM2MmMuNTc1LjIzOCAxLjA5OS41ODggMS41NCAxLjAzbDEuMDYtMS4wNmE2LjI0NCA2LjI0NCAwIDAgMC0yLjAyNi0xLjM1NmwtLjU3NCAxLjM4NlptMS41NCAxLjAzIDIuMTI1IDIuMTIgMS4wNi0xLjA2My0yLjEyNS0yLjExOC0xLjA2IDEuMDYyWiIvPjwvc3ZnPg==);
}

.show-more-white:hover {
    border: none;
    background-color: var(--white-secondary-12);
}

.news {
    border: 1px solid var(--emerald-secondary-20);
    color: var(--emerald);
    background-color: transparent;
    border-radius: 64px;
    max-width: max-content;
    width: auto;
    height: auto;
    padding: 12px 24px;
    font-size: 14px;
}

.news:hover {
    border: none;
    background-color: var(--emerald-secondary-10);
    padding: 13px 25px;
}

.news.active {
    color: var(--white);
    background-color: var(--emerald);
}

.view {
    width: 40px;
    height: 40px;
    background: no-repeat center
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIiBzdHJva2U9IiMxMjE1MTciIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiPjxwYXRoIGQ9Ik0xMC41IDQuMzc1QzQuMjUgNC4zNzUgMS43NSAxMCAxLjc1IDEwczIuNSA1LjYyNSA4Ljc1IDUuNjI1UzE5LjI1IDEwIDE5LjI1IDEwcy0yLjUtNS42MjUtOC43NS01LjYyNVoiLz48cGF0aCBkPSJNMTAuNSAxMi41YTIuNSAyLjUgMCAxIDAgMC01IDIuNSAyLjUgMCAwIDAgMCA1WiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC41KSIgZD0iTTAgMGgyMHYyMEgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==);
    background-color: var(--toxicGreen);
    padding: 10px;
}

.download {
    width: 40px;
    height: 40px;
    background: no-repeat center
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjMTIxNTE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTEwIDExLjI1VjMuMTI1TTE2Ljg3NSAxMS44NzV2NC4zNzVhLjYyNC42MjQgMCAwIDEtLjYyNS42MjVIMy43NWEuNjI1LjYyNSAwIDAgMS0uNjI1LS42MjV2LTQuMzc1Ii8+PHBhdGggc3Ryb2tlPSIjMTIxNTE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTEzLjEyNSA4Ljc1IDEwIDExLjg3NSA2Ljg3NSA4Ljc1Ii8+PC9zdmc+);
    background-color: var(--lightGreen);
}

.download:hover {
    background-color: var(--lightGreenHover);
}

.read-more {
    background-color: transparent;
    width: auto;
    height: auto;
    color: var(--green);
}

.read-more:after {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-left: 8px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTIgNiA2IDUgNi01IiBzdHJva2U9IiMwMEQzMDAiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+);
}

.read-more:hover {
    background: none;
    opacity: 0.5;
}

.read-more.opened:after {
    transform: rotate(180deg);
}

.instagram-black {
    width: 96px;
    height: 96px;
    border-radius: 90%;
    background: no-repeat center
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3LjIzNCA0LjAwMWMuODc1LS4wMDMgMS43NS4wMDYgMi42MjYuMDI3bC4yMzMuMDA4Yy4yNjkuMDEuNTM0LjAyMi44NTQuMDM2IDEuMjc3LjA2IDIuMTQ4LjI2MiAyLjkxMy41NTguNzkyLjMwNSAxLjQ1OS43MTggMi4xMjYgMS4zODVhNS44OSA1Ljg5IDAgMCAxIDEuMzgzIDIuMTI1Yy4yOTcuNzY1LjQ5OCAxLjYzNy41NTggMi45MTQuMDE1LjMxOS4wMjcuNTg1LjAzNy44NTRsLjAwNy4yMzNjLjAyMS44NzUuMDMgMS43NS4wMjcgMi42MjVsLjAwMi44OTZ2MS41NzFjLjAwMi44NzYtLjAwNyAxLjc1Mi0uMDI4IDIuNjI3bC0uMDA3LjIzM2MtLjAxLjI2OS0uMDIyLjUzNC0uMDM2Ljg1NC0uMDYgMS4yNzctLjI2NCAyLjE0OC0uNTYgMi45MTNhNS44NiA1Ljg2IDAgMCAxLTEuMzgzIDIuMTI2IDUuODk4IDUuODk4IDAgMCAxLTIuMTI2IDEuMzgzYy0uNzY1LjI5Ny0xLjYzNi40OTgtMi45MTMuNTU4LS4zMi4wMTUtLjU4NS4wMjctLjg1NC4wMzdsLS4yMzMuMDA3Yy0uODc1LjAyMS0xLjc1LjAzLTIuNjI2LjAyN2wtLjg5Ni4wMDJoLTEuNTdhOTUuMDQgOTUuMDQgMCAwIDEtMi42MjctLjAyOGwtLjIzMy0uMDA3Yy0uMjg1LS4wMS0uNTctLjAyMy0uODU0LS4wMzYtMS4yNzctLjA2LTIuMTQ4LS4yNjQtMi45MTQtLjU2LS44LS4zLTEuNTI2LS43NzMtMi4xMjUtMS4zODNhNS44ODQgNS44ODQgMCAwIDEtMS4zODQtMi4xMjZjLS4yOTctLjc2NS0uNDk4LTEuNjM2LS41NTgtMi45MTMtLjAxNC0uMjg0LS4wMjYtLjU3LS4wMzYtLjg1NGwtLjAwNi0uMjMzQTk0Ljg4OCA5NC44ODggMCAwIDEgNCAxNy4yMzN2LTIuNDY3Yy0uMDA0LS44NzUuMDA1LTEuNzUuMDI2LTIuNjI1bC4wMDgtLjIzM2MuMDEtLjI2OS4wMjItLjUzNS4wMzYtLjg1NC4wNi0xLjI3OC4yNjItMi4xNDguNTU4LTIuOTE0LjMwMi0uOC43NzYtMS41MjYgMS4zODgtMi4xMjRBNS44NzYgNS44NzYgMCAwIDEgOC4xNCA0LjYzYy43NjYtLjI5NiAxLjYzNi0uNDk4IDIuOTE0LS41NThsLjg1NC0uMDM2LjIzMy0uMDA2Yy44NzUtLjAyMiAxLjc1LS4wMzIgMi42MjYtLjAzbDIuNDY3LjAwMVptLTEuMjM0IDZBNiA2IDAgMSAwIDE2IDIyIDYgNiAwIDAgMCAxNiAxMFptMCAyLjRhMy42IDMuNiAwIDEgMSAuMDAxIDcuMTk5IDMuNiAzLjYgMCAxIDEgMC03LjJ2LjAwMVptNi4zLTQuMmExLjUgMS41IDAgMCAwIDAgMi45OTggMS41IDEuNSAwIDEgMCAwLTNWOC4yWiIgZmlsbD0iIzEyMTUxNyIvPjwvc3ZnPg==);
    background-color: var(--toxicGreen);
}

.send {
    width: 56px;
    height: 56px;
    background: no-repeat center
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIuNzggMS4zMTZhLjYyNS42MjUgMCAwIDAtLjg5My42ODFMMy42NDEgOC4wNmEuNjI1LjYyNSAwIDAgMCAuNDk2LjQ0Mmw3LjExMyAxLjE5MmMuMzM1LjA2Ni4zMzUuNTQ2IDAgLjYxMmwtNy4xMTMgMS4xOTFhLjYyNS42MjUgMCAwIDAtLjQ5Ni40NDNsLTEuNzU0IDYuMDYyYS42MjUuNjI1IDAgMCAwIC44OTMuNjgxbDE2LjI1LTguMTI0YS42MjUuNjI1IDAgMCAwIDAtMS4xMThMMi43OCAxLjMxNloiIGZpbGw9IiMxMjE1MTciLz48L3N2Zz4=);
    background-color: var(--toxicGreen);
}

.next {
    width: 56px;
    height: 56px;
    background: no-repeat center
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjMTIxNTE3IiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0yLjUgOGgxMm0wIDAtNiA2bTYtNi02LTYiLz48L3N2Zz4=);
    background-color: var(--lightGreen);
}

.next:hover {
    background-color: var(--lightGreenHover);
}

.next.backwards {
    transform: rotate(180deg);
}

.learn-more-arrow {
    background-color: transparent;
    max-width: none;
    width: auto;
    height: auto;
    font-weight: 600;
    justify-content: left;
    display: inline-flex;
}

.learn-more-arrow:hover {
    background-color: transparent;
}

.learn-more-arrow:hover span {
    color: var(--green);
}

.learn-more-arrow div {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background: no-repeat center
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJtMTIgNC41IDcuNSA3LjUtNy41IDcuNS0xLjM3Ni0xLjM3NiA1LjE1LTUuMTVINC41di0xLjk0N2gxMS4yNzRsLTUuMTUtNS4xNUwxMiA0LjVaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
    background-size: 18px;
    background-color: var(--toxicGreen);
    transition: all 0.3s;
}

.learn-more-arrow:hover div {
    background-color: var(--green);
}

.learn-more-arrow.has-children div {
    margin-right: 12px;
}

.clear {
    width: 156px;
    height: 48px;
    background-color: var(--white-secondary-12);
    color: var(--white);
}

.clear:after {
    content: "";
    display: block;
    width: 16px;
    min-width: 14px;
    height: 16px;
    margin-left: 8px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIGQ9Im0xMy41IDMtMTAgMTBNMTMuNSAxMyAzLjUgMyIvPjwvc3ZnPg==);
}

.clear:hover {
    background-color: var(--white-secondary-20);
}

.instagram {
    width: 56px;
    height: 56px;
    border-radius: 90%;
    background: no-repeat center
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTExLjAyOC4wMDFjLjczLS4wMDMgMS40Ni4wMDUgMi4xODkuMDIybC4xOTQuMDA3Yy4yMjQuMDA4LjQ0NS4wMTguNzEyLjAzIDEuMDY0LjA1IDEuNzkuMjE4IDIuNDI3LjQ2NS42Ni4yNTQgMS4yMTYuNTk4IDEuNzcyIDEuMTU0LjUwOC41LjkwMSAxLjEwNCAxLjE1MyAxLjc3MS4yNDcuNjM3LjQxNSAxLjM2NC40NjUgMi40MjguMDEyLjI2Ni4wMjIuNDg4LjAzLjcxMmwuMDA2LjE5NGMuMDE3LjczLjAyNSAxLjQ1OS4wMjMgMi4xODh2Mi4wNTZjLjAwMy43My0uMDA1IDEuNDYtLjAyMiAyLjE4OWwtLjAwNi4xOTRjLS4wMDguMjI0LS4wMTguNDQ1LS4wMy43MTItLjA1IDEuMDY0LS4yMiAxLjc5LS40NjYgMi40MjdhNC44ODUgNC44ODUgMCAwIDEtMS4xNTMgMS43NzJjLS41LjUwOC0xLjEwNS45MDEtMS43NzIgMS4xNTMtLjYzNy4yNDctMS4zNjMuNDE1LTIuNDI3LjQ2NWE4MC45NyA4MC45NyAwIDAgMS0uNzEyLjAzbC0uMTk0LjAwNmMtLjczLjAxNy0xLjQ2LjAyNS0yLjE4OS4wMjNIOC45NzNjLS43My4wMDMtMS40Ni0uMDA1LTIuMTg5LS4wMjJsLS4xOTQtLjAwNmE2NS4wNDUgNjUuMDQ1IDAgMCAxLS43MTItLjAzYy0xLjA2NC0uMDUtMS43OS0uMjItMi40MjgtLjQ2NmE0Ljg5IDQuODkgMCAwIDEtMS43Ny0xLjE1M0E0LjkwNCA0LjkwNCAwIDAgMSAuNTI1IDE2LjU1Qy4yNzkgMTUuOTEzLjExIDE1LjE4Ny4wNiAxNC4xMjNhNzQuMzQzIDc0LjM0MyAwIDAgMS0uMDMtLjcxMmwtLjAwNS0uMTk0Yy0uMDE5LS43My0uMDI3LTEuNDYtLjAyNS0yLjE5VjguOTczYy0uMDAzLS43My4wMDQtMS40NTkuMDIyLTIuMTg4TC4wMyA2LjU5Yy4wMDgtLjIyNC4wMTgtLjQ0Ni4wMy0uNzEyLjA1LTEuMDY1LjIxOC0xLjc5LjQ2NS0yLjQyOEE0Ljg4IDQuODggMCAwIDEgMS42OCAxLjY4IDQuODk3IDQuODk3IDAgMCAxIDMuNDUuNTI1QzQuMDg4LjI3OCA0LjgxMy4xMSA1Ljg3OC4wNkw2LjU5LjAzbC4xOTQtLjAwNUM3LjUxNC4wMDcgOC4yNDMtLjAwMiA4Ljk3MiAwbDIuMDU2LjAwMVptLTEuMDI4IDVBNSA1IDAgMSAwIDEwIDE1IDUgNSAwIDEgMCAxMCA1Wm0wIDJBMyAzIDAgMSAxIDEwLjAwMSAxM2EzIDMgMCAwIDEgMC02di4wMDFabTUuMjUtMy41YTEuMjUgMS4yNSAwIDAgMCAwIDIuNDk4IDEuMjUgMS4yNSAwIDAgMCAwLTIuNXYuMDAyWiIvPjwvc3ZnPg==);
    background-color: var(--white-secondary-12);
}

.instagram:hover {
    background-color: var(--white-secondary-20);
}

.facebook {
    width: 56px;
    height: 56px;
    border-radius: 90%;
    background: no-repeat center
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTIwIDEwLjAyNUMyMCA0LjQ5MSAxNS41MiAwIDEwIDBTMCA0LjQ5MSAwIDEwLjAyNWMwIDQuODUyIDMuNDQgOC44OTIgOCA5LjgyNXYtNi44MTdINnYtMy4wMDhoMlY3LjUyYTMuNTA4IDMuNTA4IDAgMCAxIDMuNS0zLjUwOUgxNHYzLjAwOGgtMmMtLjU1IDAtMSAuNDUtMSAxLjAwMnYyLjAwNWgzdjMuMDA4aC0zVjIwYzUuMDUtLjUwMSA5LTQuNzcyIDktOS45NzVaIi8+PC9zdmc+);
    background-color: var(--white-secondary-12);
}

.facebook:hover {
    background-color: var(--white-secondary-20);
}

.youtube {
    width: 56px;
    height: 56px;
    border-radius: 90%;
    background: no-repeat center
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEwLjI0NSAwYy41MzQuMDAzIDEuODcuMDE0IDMuMjkuMDY0bC41MDQuMDJjMS40MjkuMDU4IDIuODU3LjE2IDMuNTY2LjMzMi45NDUuMjMyIDEuNjg3LjkxIDEuOTM4IDEuNzY5LjQgMS4zNjUuNDUgNC4wMjcuNDU2IDQuNjcyTDIwIDYuOTl2LjE1MmMtLjAwNy42NDUtLjA1NyAzLjMwOC0uNDU3IDQuNjcyLS4yNTQuODYyLS45OTcgMS41NC0xLjkzOCAxLjc3LS43MS4xNzItMi4xMzcuMjczLTMuNTY2LjMzMmwtLjUwNC4wMmMtMS40Mi4wNS0yLjc1Ny4wNjEtMy4yOS4wNjNsLS4yMzYuMDAxaC0uMjU1Yy0xLjEzLS4wMDYtNS44NTYtLjA1LTcuMzYtLjQxNi0uOTQ0LS4yMzMtMS42ODctLjkxLTEuOTM4LTEuNzdDLjA1NiAxMC40NS4wMDYgNy43ODcgMCA3LjE0MnYtLjI4NWMuMDA2LS42NDUuMDU2LTMuMzA4LjQ1Ni00LjY3Mi4yNTQtLjg2Mi45OTctMS41NCAxLjk0LTEuNzY4QzMuODk3LjA1IDguNjI1LjAwNSA5Ljc1NSAwaC40ODlaTTcuOTk4IDMuOTM4djYuMTI1TDE0IDcgOCAzLjkzOFoiLz48L3N2Zz4=);
    background-color: var(--white-secondary-12);
}

.youtube:hover {
    background-color: var(--white-secondary-20);
}

.close {
    width: 40px;
    height: 40px;
    background: no-repeat center/contain
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTE4IDYgNiAxOE0xOCAxOCA2IDYiLz48L3N2Zz4=);
    background-color: var(--white-secondary-12);
}

.close.small {
    width: 32px;
    height: 32px;
}

.close.big {
    width: 48px;
    height: 48px;
}

.close:hover {
    background-color: var(--white-secondary-20);
}

.default {
    display: block;
    background-color: var(--toxicGreen);
    color: var(--black);
    max-width: 400px;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    &:hover {
        color: var(--black);
        background-color: var(--green);
    }
    @include w($lg) {
        height: 56px;
    }
}

.white-arrow {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background:
        url("../../../images/icons/whiteArrowDown.svg") center no-repeat,
        var(--white-secondary-20);
}

.wide {
    max-width: none;
}

@media screen and (max-width: 1024px) {
    .button {
        max-width: none;
    }

    .instagram-black {
        width: 80px;
        height: 80px;
    }

    .news {
        font-size: 13px;
    }

    .learn-more-arrow div {
        width: 32px;
        height: 32px;
    }
}
