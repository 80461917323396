@import "styles/typography";
@import "styles/mixins";

.cookie-popup {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 80;
    padding: 16px;
    border-radius: 8px;
    background: #121517;
    display: flex;
    gap: 24px 40px;
    max-width: 80%;
    align-items: flex-end;
    opacity: 0;
    pointer-events: none;
    @keyframes showCookie {
        to {
            opacity: 1;
            pointer-events: all;
        }
    }
    animation: 0.5s 7s showCookie forwards;
    .cookie-popup-content {
    }
    .cookie-popup__title {
        display: flex;
        @include description-medium;
    }
    .cookie-popup__text {
        padding-top: 8px;
        @include body1;
        color: rgba(#fff, 0.7);
        a {
            color: #fff;
        }
    }
    .button-submit {
        width: auto;
        padding-left: 56px;
        padding-right: 56px;
    }
    @include w($lg) {
        flex-direction: column;
        left: 16px;
        max-width: none;
        align-items: flex-start;
        .button-submit {
            width: 100%;
            height: 48px;
        }
    }
}
