@import "styles/mixins";

@mixin header-dropdown-styles {
    @keyframes headerDropdownShow {
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    display: none;
    position: absolute;
    padding: 16px 24px 24px;
    top: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: var(--black);
    border-radius: 0 0 8px 8px;
    transform: translateY(-10px);
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-name: headerDropdownShow;
    animation-play-state: paused;
    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 6px;
        background-color: var(--black);
        position: absolute;
        top: -5px;
        left: 0;
        right: 0;
    }
}

header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .container {
        padding: 16px 16px 0;
    }
}

.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 50;
    background-color: rgba(3, 92, 103, 0.2);
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: overlayShow 0.3s forwards;
    @keyframes overlayShow {
        to {
            opacity: 1;
        }
    }
    @include min-w($lg) {
        display: none;
    }
}

.burger-button {
    flex-shrink: 0;
    display: none;
    line {
        transition: 0.3s;
        transform-origin: center;
    }
    @include w($lg) {
        display: flex;
    }
}

.header {
    position: relative;
    background-color: var(--black);
    border-radius: 8px;
    &__main {
        padding: 0 24px;
        height: 64px;
        display: flex;
        align-items: center;
        @include w($lg) {
            height: 56px;
            padding: 0 8px;
        }
    }
    &-logo {
        flex-shrink: 0;
        margin-right: 60px;
    }
    &-nav {
        display: flex;
        align-self: stretch;
        align-items: center;
        @include w($lg) {
            // create dropdown for mobile
            @include header-dropdown-styles;
            padding: 16px;
        }
    }
    &-finish {
        padding-left: 20px;
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 0 4px;
    }
    @include min-w($lg) {
        &:has(.navigation-item:hover) .navigation-item {
            opacity: 0.5;
            // this style affected nav-dropdown
        }
    }

    @include w($lg) {
        &-logo {
            margin-right: 0;
        }
        &.mobile-menu-isOpen {
            .header-nav {
                display: block;
                animation-play-state: running;
            }
            .burger-button {
                line {
                    &:first-child {
                        transform: rotate(45deg) translate(0, 4px);
                    }
                    &:last-child {
                        transform: rotate(-45deg) translate(0, -3px);
                    }
                }
            }
        }
    }
}

.navigation-item {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: default;
    display: flex;
    align-items: center;
    padding: 0 10px;
    transition: opacity 0.3s;

    @include min-w($lg) {
        & + & {
            margin-left: 30px;
        }
        &:has(+ div) {
            height: 100%;
        }
    }

    &__name {
        transition: 0.3s;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        gap: 0 8px;
        &-arrow {
            flex-shrink: 0;
            transition: transform 0.3s;
        }
    }

    &__dropdown {
        @include min-w($lg) {
            @include header-dropdown-styles;
        }

        &-items {
            display: flex;
            justify-content: space-between;
            gap: 0 8px;
        }
        &-button {
            padding-top: 24px;
            .button {
                color: #fff;
            }
        }
        @include w($lg) {
            display: none;
            width: 100%;
            position: relative;
            &-items {
                flex-direction: column;
                gap: 4px;
            }
        }
    }
    @include min-w($lg) {
        &:hover {
            opacity: 1 !important;
            .navigation-item {
                &__name {
                    color: var(--toxicGreen);
                    &-arrow {
                        transform: scaleY(-1);
                    }
                }
                &__dropdown {
                    display: block;
                    animation-play-state: running;
                }
            }
        }
    }

    @include w($lg) {
        width: 100%;
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
        &__name {
            height: 50px;
            justify-content: space-between;
            width: 100%;
            &.opened {
                color: var(--toxicGreen);
                .navigation-item__name-arrow {
                    transform: scaleY(-1);
                }
                & + .navigation-item__dropdown {
                    display: block;
                    opacity: 0;
                    transform: translateY(-10px);
                    animation-fill-mode: forwards;
                    animation-duration: 0.5s;
                    animation-name: headerDropdownShow;
                    animation-play-state: running;
                }
            }
        }
    }
}

.search-dropdown {
    @include header-dropdown-styles;
    &.opened {
        display: block;
        animation-play-state: running;
    }
}

.header-navigation-card {
    width: 100%;
    padding: 16px;
    height: 160px;
    border-radius: 4px;
    background-color: var(--white-secondary-12);
    font-size: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;
    &__title {
        line-height: 1;
        svg {
            transition: opacity 0.3s;
            margin-left: 8px;
            opacity: 0;
        }
    }
    &__icons {
        display: flex;
        gap: 0 4px;
    }
    img {
        max-width: max-content;
        max-height: 48px;
    }
    @include w($xl) {
        font-size: 28px;
    }
    @include min-w($lg) {
        &:hover {
            color: var(--green);
            background-color: var(--white-secondary-20);
            svg {
                opacity: 1;
            }
        }
    }

    @include w($lg) {
        font-size: 20px;
        height: 64px;
        flex-direction: row-reverse;
        &__title {
            display: flex;
            align-items: center;
        }
        &__icons {
            justify-content: flex-end;
            img:not(:first-child) {
                display: none;
            }
        }
    }
}
