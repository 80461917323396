@import "styles/mixins";

.new-dropdown {
    display: block;
    .selected-item {
        display: flex;
        align-items: center;
    }
}
.select-input {
    position: relative;
    width: 100%;
    img {
        width: 20px;
        height: 20px;
    }
}

.select-input input {
    display: none;
}

.select-input .--dropdown__value {
    position: relative;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 40px 0 24px;
    border-radius: 2px;
    background-color: var(--white-secondary-12);
    transition: border-radius 0.4s;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease-in-out;
    @include w($lg) {
        height: 56px;
    }
}

.select-input .--dropdown__value span img {
    margin-right: 8px;
}

.select-input .--dropdown__value::after {
    content: "";
    position: absolute;
    top: calc(50% - 8px);
    right: 15px;
    width: 16px;
    height: 16px;
    margin-top: -3px;
    background: no-repeat center / contain
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIGQ9Im0zIDkgOSA3LjVMMjEgOSIvPjwvc3ZnPg==);
    transition: all 0.3s ease-in-out;
}

.select-input .--dropdown__value::before {
    content: "";
    position: absolute;
    top: calc(50% - 8px);
    right: 15px;
    width: 16px;
    height: 16px;
    margin-top: -3px;
    opacity: 0;
    transform: rotate(-180deg);
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0IDExIDggNmwtNiA1IiBzdHJva2U9IiMwRjAiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+);
    transition: all 0.3s ease-in-out;
}

.select-input .--dropdown__value:hover {
    background-color: var(--white-secondary-20);
}

.select-input.-active .--dropdown__value {
    border-radius: 5px 5px 0 0;
    background-color: var(--white-secondary-20);
}

.select-input.-active .--dropdown__value::after {
    transform: rotate(180deg);
    opacity: 0;
}

.select-input.-active .--dropdown__value::before {
    transform: rotate(0);
    opacity: 1;
}

.select-input .--dropdown__value-text {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.select-input .--dropdown__clear {
    display: none;
}

.select-input.-selected .--dropdown__clear {
    display: block;
}

.select-input ul {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 8px 0;

    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: var(--white-secondary-12);
    opacity: 0;
    transform: translateY(10px);
    transition:
        opacity 0.4s,
        transform 0.4s;

    overflow-y: auto;
    pointer-events: none;
    max-height: calc(64px * 4);
    @include w($lg) {
        max-height: calc(56px * 4);
    }
}

.select-input.-active ul {
    opacity: 1;
    transform: translateY(0px);
    pointer-events: auto;
    z-index: 5;
    background-color: var(--darkGrey);
}

.select-input ul li {
    height: 64px;
    padding: 7px 15px 7px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    @include w($lg) {
        height: 56px;
    }
}

.select-input ul li:hover {
    background-color: var(--white-secondary-20);
}

.select-input ul li img {
    margin-right: 8px;
}

.select-input ul li.-active {
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
}

.select-input .filter-dropdown-option {
    margin-left: 8px;
}
