.instagram-card {
    width: 264px;
    height: 296px;
    padding: 12px;
    border: 1px solid var(--white-secondary-20);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    backdrop-filter: blur(40px);
    border-radius: 4px;
}

.instagram-card > img {
    margin-bottom: 12px;
}

.icons-wrapper {
    display: flex;
    justify-content: space-between;
}

.left-icons-wrapper {
    display: flex;
    gap: 8px;
}

@media screen and (max-width: 1024px) {
    .instagram-card {
        width: 178px;
        height: 201px;
        padding: 9px;
    }

    .instagram-card > img {
        margin-bottom: 9px;
    }

    .icons-wrapper img {
        width: 14px;
    }

    .left-icons-wrapper {
        gap: 6px;
    }
}
