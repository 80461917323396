@import "styles/mixins";

.contacts-cards {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 1fr;
    gap: 8px;
    padding: 24px 0 40px;
    @include w($xl) {
        grid-template-columns: 1fr;
    }
}

.contact-card {
    border-radius: 8px;
    padding: 24px;
    background-color: var(--black);
    color: #fff;

    &__title {
        text-transform: uppercase;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        gap: 0 12px;
    }
    @include w($md) {
        padding: 16px;
        &__title {
            margin-bottom: 16px;
        }
    }
}
