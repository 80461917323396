@import "styles/mixins";

.marquee-gallery-section {
    border-radius: 16px;
    background: var(--black);
    padding: 32px 0;
    h2 {
        margin: 0 0 32px 32px;
    }
    @include w($lg) {
        padding: 16px 0 8px;
        h2 {
            margin: 0 0 24px 16px;
        }
    }
}

.marquee-gallery {
    display: grid;
    gap: 12px 0;
    @include w($lg) {
        gap: 4px;
    }
}

.marquee-container {
    position: relative;
    overflow: hidden;
    &:hover {
        animation-play-state: paused;
    }
    .marquee-area {
        @keyframes moveLeft {
            from {
                transform: translateX(0);
            }
        }
        display: inline-block;
        white-space: nowrap;
        animation-name: moveLeft;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-play-state: inherit;
    }
    .marquee-item {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        width: 448px;
        height: 290px;
        border-radius: 8px;
        margin-right: 16px;
        @include w($lg) {
            margin-right: 8px;
            width: 280px;
            height: 180px;
        }
    }
}
