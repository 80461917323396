.button-submit {
    background-color: var(--toxicGreen);
    color: var(--black);
    font-size: 16px;
    width: 280px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s;
    &:disabled {
        cursor: default;
        opacity: 0.3;
    }
    &:hover:not(:disabled) {
        background-color: var(--green);
    }
}
