.solutions {
    position: relative;
    background-color: var(--black);
    padding: 32px;
    background: var(--greenLinearGradient), url("../../../images/UniferX Symbol 2x.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--black);
    background-size: contain;
    border-radius: 16px;
    overflow: hidden;
    height: 784px;
    .gradient-text {
        max-width: 370px;
        display: none;
    }
    @media screen and (max-width: 1024px) {
        padding: 16px;
        background-size: 170%;
        .gradient-text {
            display: block;
        }
    }
}
