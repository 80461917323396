@import "styles/mixins";

.filter-sidebar {
    position: sticky;
    top: 88px;
    background-color: var(--black);
    border-radius: 16px;
    padding: 32px;
    &.without-sticky {
        position: relative;
        top: auto;
        margin-bottom: 8px;
    }
    &__header {
        padding-bottom: 32px;
        display: flex;
        align-items: center;
        gap: 8px;
        text-transform: uppercase;
    }
    &-reset {
        margin-top: 32px;
        width: 100%;
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        transition: 0.3s all;
        &:hover {
            border-color: transparent;
            background-color: rgba(255, 255, 255, 0.12);
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        gap: 32px 0;
    }
    @include w($lg) {
        padding: 16px;
    }
}
