@import "styles/typography";
@import "styles/mixins";

.new-footer {
    margin-bottom: 8px;
    border-radius: 16px;
    padding: 32px 32px 0;
    background: linear-gradient(180deg, rgba(25, 99, 107, 0) 35%, rgba(0, 255, 0, 0.7) 100%);
    background-color: var(--black);

    &-row {
        display: grid;
        padding-bottom: 55px;
        $gap: 0px;
        @function col-width($col) {
            $col-width: calc(100% / 12);
            @return calc(#{$col-width * $col} - $gap / 2);
        }
        &:nth-child(1) {
            grid-template-columns: col-width(4) col-width(3) col-width(2) col-width(3);
        }
        &:nth-child(2) {
            grid-template-columns: col-width(4) col-width(3) col-width(5);
        }
    }

    &-start {
        p {
            max-width: 300px;
            margin-top: 16px;
            opacity: 0.5;
        }
    }
    .footer-title {
        @include body1;
        opacity: 0.3;
        margin-bottom: 24px;
    }
    .footer-social {
        display: flex;
        gap: 8px;
    }
    a {
        display: flex;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
        text-transform: uppercase;
        transition: 0.3s ease-out;
        &:hover {
            color: var(--toxicGreen);
        }
    }
    .footer-links-block {
        a + a {
            margin-top: 16px;
        }
    }
    .footer-contact-link {
        display: flex;
        align-items: center;
        gap: 4px;
        svg {
            flex-shrink: 0;
        }
    }
    @include w($lg) {
        padding: 16px;
        &-row {
            display: block;
            padding-bottom: 24px;
        }
        .footer-links-block {
            margin-top: 24px;
        }
    }
}

.footer-copy {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 35px 0;
    align-items: center;

    .copyright {
        @include body1;
        opacity: 0.5;
    }
    .policies {
        display: flex;
        gap: 16px 32px;
    }
    .goodface-bar {
        display: flex;
        align-items: center;
        .body1 {
            display: block;
            opacity: 0.5;
            margin-right: 4px;
        }
        a {
            align-items: center;
            text-transform: capitalize;
            span {
                margin-top: 2px;
                margin-right: 4px;
            }
        }
    }
    @include w($lg) {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 16px;
        .copyright {
            margin-top: 8px;
            order: 3;
        }
        .policies {
            margin-bottom: 24px;
            order: -1;
            flex-direction: column;
        }
    }
}
