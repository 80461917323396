@import "styles/mixins";

.job-labels {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.job-label {
    border-radius: 64px;
    border: 1px solid rgba(18, 21, 22, 0.1);
    background-color: var(--emerald);
    height: 40px;
    padding: 0 20px;
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 0 8px;
    color: #fff;
    align-items: center;
    text-transform: uppercase;
    @include w($lg) {
        height: 32px;
        padding: 0 16px;
    }
}
