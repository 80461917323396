@import "styles/typography";
@import "styles/mixins";

.determinate {
    z-index: 1;
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    background-color: var(--black);
    padding: 32px;
    border-radius: 8px;
    opacity: 0;
    transform: translateY(-10px);
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-name: headerDropdownShow;
    animation-play-state: running;
    width: 360px;
    max-width: 100%;
    &__title,
    &__country {
        @include h4;
        margin-bottom: 32px;
        text-align: center;
    }
    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .button {
            max-width: none;
            width: 100%;
        }
        .outlined {
            background-color: transparent;
            color: #fff;
            border: 1px solid #fff;
            &:hover {
                opacity: 0.5;
            }
        }
    }
    // .select-input {
    //     max-width: none;
    // }
    @include w($md) {
        padding: 16px;
        top: calc(100% + 8px);
        width: 100%;
        &__title {
            margin-bottom: 16px;
        }
        &__country {
            margin-bottom: 24px;
        }
        .button {
            height: 56px;
        }
    }
}
