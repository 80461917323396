@import "styles/mixins";

.news-wrapper-image {
    width: 100%;
    max-height: 360px;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    filter: blur(2px);
}

.news-box {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px;
    background-color: var(--white-secondary-12);
    background-color: rgba(0, 0, 0, 0.2);
    .button {
        color: #fff;
    }
    @include w($lg) {
        padding: 24px 16px 16px;
        border-radius: 12px;
    }
}

.news-box p {
    top: 0;
    left: 0;
}

.news-box .description-demibold {
    margin: 16px 0;
}

.news-time-data {
    display: flex;
    gap: 16px;
}

.news-time-data span img {
    width: 16px;
    margin-right: 6px;
}

.news-box-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dots-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.information-wrapper__news-wrapper {
    .swiper-pagination {
        position: absolute;
        right: 32px;
        bottom: 50px;
        z-index: 1;
        display: flex;
        gap: 0 8px;
        cursor: pointer;
        @include w($lg) {
            right: 16px;
            bottom: 29px;
        }
    }

    .swiper-pagination-bullet {
        display: block;
        width: 6px;
        height: 6px;
        background-color: var(--white);
        border-radius: 6px;
        opacity: 0.3;
        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}

.hero {
    position: relative;
    height: 496px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;

    @include w($lg) {
        height: 320px;
        margin: 0;
    }

    & video {
        position: absolute;
        max-width: 1600px;
        width: 100%;
        height: 496px;
        object-fit: cover;
        z-index: -1;
        border-radius: 16px;

        @include w($lg) {
            height: 320px;
        }
    }

    .gradient-text {
        z-index: 1;
        margin: 0 32px 32px;
        max-width: 735px;
        @include w($lg) {
            margin: 0 16px 16px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 256px;
        background: linear-gradient(180deg, rgba(9, 20, 21, 0) 0%, #091415 100%);
        border-radius: 16px;
        z-index: -1;

        @include w($lg) {
            border-radius: 12px;
        }
    }
}

.information-wrapper {
    display: flex;

    @include w($lg) {
        flex-direction: column-reverse;
    }

    &__offer-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 1226px;
        width: 100%;
        height: 280px;
        background: linear-gradient(180deg, rgba(25, 99, 107, 0) 20%, rgba(0, 255, 0, 0.7) 100%),
            url("../../../images/UniferXSymbol.jpg");
        background-size: cover;
        background-position: right;
        border-radius: 16px;
        padding: 32px;
        margin-right: 8px;
        .gradient-text {
            max-width: 400px;
        }
        @include w($lg) {
            display: none;
        }
    }

    &__box-for-border {
        position: absolute;
        width: 50px;
        height: 50px;
        border-right: 8px solid var(--white);
        border-bottom: 8px solid var(--white);
        border-bottom-right-radius: 32px;
        top: 374px;
        right: 0;

        @include w($lg) {
            display: none;
        }

        &.location-left {
            top: 454px;
            right: 374px;
        }

        &.radius16 {
            border-bottom-right-radius: 16px;
        }
    }

    &__news-wrapper {
        position: relative;
        margin: -88px -8px 0 -8px;
        border: 8px solid white;
        max-height: 360px;
        height: 100%;
        max-width: 366px;
        width: 100%;
        border-radius: 16px;
        box-sizing: content-box;
        overflow: hidden;
        background-color: var(--white);

        @include w($lg) {
            max-width: 100%;
            margin-top: 0;
        }

        & .swiper-slide {
            height: 360px;

            @include w($lg) {
                height: 240px;
            }
        }
    }
}
