@import "styles/mixins";

.product-sidebar {
    padding: 32px;
    border-radius: 16px;
    background-color: var(--black);
    &__title {
        margin-bottom: 24px;
    }
    @include w($xl) {
        padding: 16px;
    }
}

.product-sidebar-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.product-sidebar-card {
    text-align: center;
    overflow: hidden;
    flex: 0 1 calc(33.33% - 8px);
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 8px;
    background-color: var(--white-secondary-12);
    transition: background-color 0.3s;
    img {
        width: 100%;
        transition: 0.3s;
    }
    &:hover {
        border-radius: 8px;
        background-color: var(--white-secondary-20);
        img {
            @include product-item--hover;
        }
    }
    @include w($xl) {
        padding: 10px;
    }
}
