@import "styles/mixins";

.pdf-box {
    .button {
        margin-top: 24px;
        max-width: 100%;
    }
}

.pdf-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    @include w($md) {
        grid-template-columns: 1fr;
    }
}

.pdf-card {
    background-color: var(--white);
    border-radius: 8px;
    padding: 24px;
    transition: background-color 0.3s ease-out;
    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;

        &__right-icons {
            display: flex;
            gap: 8px;

            & .button {
                margin-top: 0;
            }
        }
    }
    &:hover {
        background-color: var(--greyHover);
    }
}
