@import "styles/mixins";

.form-section {
    .inner-container {
        padding-top: 40px;
        padding-bottom: 80px;
        @include w($lg) {
            padding-bottom: 64px;
        }
    }
    .with-sidebar-layout__right {
        align-self: stretch;
    }
}

.new-form-sidebar {
    position: relative;
    height: 100%;
    border-radius: 16px;
    background-color: var(--black);
    &-inner {
        padding: 80px 24px;
        .gradient-text {
            word-break: break-word;
        }
        .label-button {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
        }
    }
    &__gradient {
        pointer-events: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60%;
        background: var(--greenLinearGradient);
        border-bottom-right-radius: 16px;
    }
    &::after {
        content: url("data:image/svg+xml,%3Csvg  viewBox='0 0 87 87' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0.372559V87H86.6274C82.384 87 78.3142 85.3145 75.3137 82.3135L4.68628 11.6865C1.68579 8.68555 0 4.61621 0 0.372559Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
        display: block;
        position: absolute;
        bottom: -1px;
        left: -1px;
        z-index: 3;
        width: 87px;
        height: 87px;
    }
    &::before {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 227 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M226.566 0H0V72C0 63.1631 7.16333 56 16 56H156.692C160.899 56 164.936 54.3428 167.931 51.3877L215.327 4.6123C218.321 1.65723 222.359 0 226.566 0Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 3;
        width: 50%;
        height: 72px;
    }
    @include w(1366) {
        &::before {
            width: 65%;
            left: -2px;
        }
    }
    @include w($lg) {
        &-inner {
            padding: 75px 16px;
        }
        &::after {
            width: 56px;
            height: 56px;
        }
        &::before {
            width: 220px;
        }
    }
}

.career-form {
    .description-medium {
        color: #d0d0d1;
        p {
            margin-bottom: 32px;
            a {
                color: var(--toxicGreen);
            }
        }
    }
}
.career-form-section {
    @include w($lg) {
        .inner-container {
            padding-top: 16px;
        }
    }
}
