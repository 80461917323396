@import "styles/mixins";

.breadcrumbs {
    background-color: var(--black-secondary-30);
    backdrop-filter: blur(20px);
    padding: 8px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    width: fit-content;
    flex-wrap: wrap;

    @include w($lg) {
        padding: 5px;
        gap: 5px;
    }

    .link-wrapper {
        height: 14px;
        display: flex;
        align-items: center;

        & img {
            margin-top: 1px;

            @include w($lg) {
                width: 12px;
            }
        }
    }

    .breadcrumbs-dot {
        width: 4px;
        height: 4px;
        background-color: var(--white);
        opacity: 0.5;
        border-radius: 2px;
    }

    .breadcrumbs-span {
        display: flex;
        align-items: center;
    }

    .breadcrumbs-span:last-child {
        opacity: 0.5;
    }

    .margin-left {
        margin-left: 8px;
    }
    a {
        text-transform: uppercase;
        display: flex;
    }
}
