@import "styles/mixins";
@import "styles/typography";

.container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1600px;
    padding: 0 8px;
}

.inner-container {
    // DONT TOUCH
    padding-top: 0;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 40px;
    @include w($lg) {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 40px;
    }

    // detail crop of nutritions
    // Detailed trial results page
    // legal info
    // terms of conditions
    &.type-1 {
        padding-top: 80px;
        padding-bottom: 80px;
        @include w($lg) {
            padding-top: 8px;
            padding-bottom: 64px;
        }
    }
    // products
    // resources
    // about-product => main-text
    // related-product => main-text
    // trial-results => main-text
    // information-materials => main-text
    // contacts => main-text
    // about-us => main-text
    // homepage => latest news
    &.type-2 {
        @include w($lg) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
    // crop nut programm => main-text
    &.type-3 {
        padding-bottom: 0;
        @include w($lg) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
    // END OF DONT TOUCH
}

.main-text-container {
    padding: 80px 0 40px;
    position: relative;
    .label-button {
        margin-bottom: 24px;
    }
    &.with-absolute-label {
        .label-button {
            position: absolute;
            top: 80px;
            left: 0;
        }
    }
    @include w($lg) {
        padding: 64px 0 24px;
        .label-button {
            margin-bottom: 16px;
        }
        &.with-absolute-label {
            .label-button {
                position: relative;
                top: auto;
                left: auto;
            }
        }
    }
}

.offset-left-4 {
    margin-left: calc(((100% / 12) * 4) + 8px);
    @include w($lg) {
        margin: unset;
    }
}

.max-width-760 {
    max-width: 760px;
}
.max-width-1024 {
    max-width: 1024px;
}

.box {
    padding: 32px;
    border-radius: 16px;
    background-color: var(--grey);
    color: var(--black);
    h3 {
        margin-bottom: 32px;
    }
    @include w($xl) {
        padding: 16px;
        h3 {
            margin-bottom: 24px;
        }
    }
}

.opacity-50 {
    opacity: 0.5;
}
.opacity-70 {
    opacity: 0.7;
}
.opacity-80 {
    opacity: 0.8;
}
.main-logo-icon {
    width: 158px;
    height: 48px;
    flex-shrink: 0;
    @include w($lg) {
        width: 132px;
        height: 40px;
    }
}
.full-width {
    width: 100% !important;
    max-width: none !important;
}

.pb-24 {
    padding-bottom: 24px;
}
.pb-40 {
    padding-bottom: 40px;
}
.pb-8 {
    padding-bottom: 8px;
}
.pt-8 {
    padding-top: 8px;
}
.mt-8 {
    margin-top: 8px;
}
.radius-8 {
    border-radius: 8px;
}
.radius-16 {
    border-radius: 16px;
}
.gradient-number {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 24px;
    background-repeat: no-repeat;
    span {
        z-index: 1;
        -webkit-text-fill-color: transparent;
        font-size: 14px;
        font-weight: 600;
    }
    &.light {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.75' y='0.75' width='22.5' height='22.5' rx='11.25' stroke='url(%23paint0_linear_46_1930)' stroke-width='1.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_46_1930' x1='0' y1='12' x2='24' y2='12' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='%2300FF00'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        span {
            background: var(--greenWhiteGradient);
            -webkit-background-clip: text;
        }
    }
    &.dark {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.75' y='0.75' width='22.5' height='22.5' rx='11.25' stroke='url(%23paint0_linear_53_1976)' stroke-width='1.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_53_1976' x1='0' y1='11.988' x2='24' y2='11.988' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2319636B'/%3E%3Cstop offset='1' stop-color='%2300FF00'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        span {
            background: var(--greenEmeraldGradient);
            -webkit-background-clip: text;
        }
    }
    @include w($lg) {
        margin-bottom: 16px;
    }
}

.angle-decor {
    position: absolute;
    width: 16px;
    height: 32px;
    backdrop-filter: blur(5px);
}

.article-content {
    max-width: 940px;
    padding: 80px 16px;
    margin: 0 auto;
    color: var(--black);

    $desktop-margin: 32px;
    $mobile-margin: 24px;

    @include w($lg) {
        padding: 64px 24px;
    }

    a {
        color: var(--green);
    }

    h1,
    h2,
    h3,
    h4,
    & > p,
    ul,
    ol,
    figure,
    blockquote,
    .box {
        margin-bottom: $desktop-margin;
        @include w($lg) {
            margin-bottom: $mobile-margin;
        }
    }

    h5 {
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 24px;
        @include w($lg) {
            font-size: 20px;
        }
    }

    h6 {
        font-size: 22px;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 24px;
        @include w($lg) {
            font-size: 18px;
        }
    }

    p {
        @include body1;
    }

    ul,
    ol {
        padding-left: 42px;
        li {
            @include body1;
            position: relative;
            & + li {
                margin-top: 12px;
            }
        }
        @include w($lg) {
            padding-left: 24px;
        }
    }
    ul {
        li {
            &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6' fill='none'%3E%3Ccircle cx='3' cy='3' r='3' fill='%23035C67'/%3E%3C/svg%3E");
                position: absolute;
                left: -18px;
                top: 0;
            }
        }
    }
    ol {
        list-style: decimal;
        li {
            &::marker {
                color: var(--emerald);
            }
        }
    }
    strong {
        font-weight: 600;
    }
    hr {
        border-top: 1px solid rgba(3, 92, 103, 0.1);
        margin: $desktop-margin 0;
        @include w($lg) {
            margin: $mobile-margin 0;
        }
    }
    figure {
        img {
            width: 100%;
        }
        figcaption {
            margin-top: 16px;
            opacity: 0.6;
        }
    }
    blockquote {
        font-size: 24px;
        font-weight: 500;
        position: relative;
        padding: 24px 24px 24px 40px;
        border-radius: 16px;
        background: rgba(3, 92, 103, 0.1);
        &::before {
            content: "";
            position: absolute;
            top: 24px;
            left: 24px;
            bottom: 24px;
            display: block;
            width: 3px;
            background-color: var(--emerald);
        }
        @include w($lg) {
            font-size: 20px;
            padding: 16px 16px 16px 40px;
            &::before {
                left: 20px;
            }
        }
    }
    .box {
        padding: 24px;
        display: grid;
        grid-template-columns: 0.4fr 0.7fr;
        gap: 24px;
        @include w($md) {
            grid-template-columns: 1fr;
        }
    }
}
