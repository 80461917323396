@import "styles/mixins";
@import "styles/typography";

.general-info-box {
    p {
        margin-bottom: 16px;
        @include body1;
        color: #3e4042;
    }
    img {
        width: 100%;
        border-radius: 8px;
        margin: 16px 0 32px;
    }
    a {
        color: var(--green);
        transition: 0.3s ease-out;
        &:hover {
            color: var(--toxicGreen);
        }
    }
    .button {
        margin-top: 20px;
    }
    &__inner {
        overflow: hidden;
        &.closed {
            // &::after {
            //     content: "";
            //     position: absolute;
            //     bottom: 38px;
            //     left: 0;
            //     right: 0;
            //     height: 40px;
            //     background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, var(--grey) 100%);
            //     @include w($lg) {
            //         bottom: 32px;
            //     }
            // }
        }
    }
    // .button {
    //     display: inline-flex;
    // }
}
