@import "styles/mixins";

.header-button {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
    flex-shrink: 0;
    gap: 0 8px;

    &:has(span) {
        width: auto;
        padding: 0 12px;
    }

    &--small {
        width: 40px;
        height: 40px;
    }
    &-close {
        background-color: var(--white-secondary-12);
    }
    &:hover {
        color: var(--toxicGreen);
    }
    @include w($xl) {
        background-color: var(--white-secondary-12);
        span {
            display: none;
        }
    }
    @include w($lg) {
        padding: 0 !important;
        width: 40px !important;
        height: 40px;
    }
}
